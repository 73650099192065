import { useTranslation, Trans } from "react-i18next";
import CustomButton from "../../../components/CustomButton";
import InstructionsIcon from './InstructionsIcon';

// Removes backslash from string
const sanitiseString = string => string.replace(/\\/g, ' ');

const PrepareToUploadState = ({
    primaryFocusRef,
    title,
    subTitle,
    handleStartCamera,
    handleSelectFiles,
    brandingColor,
 }) => {
  const { t } = useTranslation();
  return (
    <>       
        <h1 
            className='page-title'
            style={{

            }}
            ref={primaryFocusRef}
            tabIndex={0}
        >
        <Trans
            i18nKey='idpal_proof_of_address_title'
            values={{documentType: sanitiseString(title)}}
            components={{
                br: <br />,
            }}
        />
        </h1>
        {subTitle && subTitle.length > 0 &&
            <h2 className='page-subtitle'>
            {sanitiseString(subTitle)}
            </h2>
        }           
        <div className='image-wrapper'>
            <InstructionsIcon fill={brandingColor}/>                
        </div>
    
        <div className='cta-container'>
            <CustomButton               
                type='customHover'
                className={'btn outline'}
                label={t('idpal_take_a_picture')}
                handleClick={handleStartCamera}
            />
            <CustomButton
                className='btn'
                label={t('idpal_choose_a_file')}
                handleClick={handleSelectFiles}
                outlined
            />
        </div>
    </>
  );
}
export default PrepareToUploadState;