import SelfieImg from '../../../assets/img/Selfie-hand.png'
const LivenessTestIcon = ({ fill }) => (
    <svg
      style={{
        backgroundImage: `url(${SelfieImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left'
      }}
      viewBox="0 0 300 300" 
      fill="none"       
    >
        <circle cx="150" cy="118" r="110" fill={fill} fillOpacity="0.25"/>
        <rect y="36" width="238" height="238" fill="url(#pattern0_0_1)"/>
        <defs>
            <pattern id="pattern0_0_1" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use transform="scale(0.00195312)"/>
            </pattern>      
        </defs>
    </svg>);

export default LivenessTestIcon;