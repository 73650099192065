

import { ACTION_LABELS } from '../../../config/dataDogActionLabels';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/CustomButton';

const ACTION = ACTION_LABELS.errors;

const ErrorCaptureState = ({
    primaryFocusRef,
    handleCaptureErrorRetry,
    isRetryLimitReached,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <h1
                className='large-text u-text-center u-btm-buffer'
                ref={primaryFocusRef}
                tabIndex={0}
            >
                {!isRetryLimitReached
                    ? t(
                            'idpal_application_encountered_an_error'
                        )
                    : t('idpal_poa_error_message')}
            </h1>

            {!isRetryLimitReached && (
                <CustomButton
                    id={'retry'}
                    label={t('idpal_try_again')}
                    className={'btn'}
                    handleClick={
                        handleCaptureErrorRetry
                    }
                    actionDataLabel={
                        ACTION.errorRetryButton
                    }
                />
            )}
        </>
    )
};
export default ErrorCaptureState;