/**
 * @prettier
 */

// React Packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

// Actions
import { completeStage } from './actions/submissionStateActions';
import { clearError } from './actions/navigatorActions';

// Services
import apiService from '../services/api/api';
import logService from '../services/shared/logService';
import DataDogService from '../services/shared/datadogService';
import {
    getCompanyName,
    isSubmissionComplete,
    sanitiseImage,
} from '../services/shared/helpers';
import { regenerateSession } from '../services/shared/authenticationService';

//  Components
import Header from './Header';
import Navigator from './Navigator';
import CustomButton from './NewButton';
import StageComplete from './Messages/StageComplete';

// Images
import livenessSvg from '../assets/img/liveness.svg';
import loadingGif from '../assets/gifs/loading.gif';
import livenessInstructionGif from '../assets/gifs/passive_instruction.gif';

// Config
import { imageAlt } from '../config/accessabilityRules';
import { ACTION_LABELS } from '../config/dataDogActionLabels';
import InstructionModal from './Messages/InstructionModal';

// ID Live Face SDK Development or Prod version
if (process.env.REACT_APP_IDLIVEFACE_VERSION === 'dev') {
    import('idlive-face-capture-web-development');
} else {
    import('idlive-face-capture-web');
}

const ACTION = ACTION_LABELS.captureSelfie;

class CaptureSelfie extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.state = {
            loading: true,
            isSDKInitialized: false,
            renderSDK: false,
            initializationTimer: '',
            detectionMessage: '',
            messageClass: 'liveness-frame--positive',
            showFrame: false,
            refreshAttempted: false,
            navigation: {
                action: 'load',
                props: null,
            },
        };
        this.openCamera = this.openCamera.bind(this);
        this.configureCapture = this.configureCapture.bind(this);
        this.updateMessage = this.updateMessage.bind(this);
        this.submitLivenessImage = this.submitLivenessImage.bind(this);
        this.processImage = this.processImage.bind(this);
        this.fallbackCapture = this.fallbackCapture.bind(this);
        this.initSDK = this.initSDK.bind(this);
    }

    componentDidMount() {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_liveness');

        // Wait before placing SDK on page
        setTimeout(this.initSDK, 500);
    }

    // Initialize IDR&D SDK, with delay to prepare page content
    initSDK() {
        // Fallback timer, if initialization takes too long, fallback to native
        const timerId = setTimeout(() => {
            this.fallbackCapture('Initialization timeout');
        }, 12000);

        // Render SDK and store timer
        this.setState({
            renderSDK: true,
            initializationTimer: timerId,
        });

        // Wait for initalisation
        const idliveFaceCapture = document.querySelector('idlive-face-capture');
        idliveFaceCapture.addEventListener('initialize', () => {
            // Configure Options
            this.configureCapture(
                document.querySelector('idlive-face-capture')
            );

            // Mark as initalized
            this.setState({ loading: false, isSDKInitialized: true });

            // Log to DataDog
            DataDogService.log('IDR&D Camera Initialized');

            // Clear Timeout
            clearTimeout(this.state.initializationTimer);
        });
    }

    componentDidUpdate() {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets document title
        const { t } = this.props;
        if (this.state.livenessError) {
            document.title = t('idpal_doc_title_liveness_error');
        } else {
            document.title = t('idpal_doc_title_liveness');
        }
    }

    fallbackCapture(message) {
        DataDogService.log(
            'Falling back to native ID-Pal Liveness capture. ' + message
        );

        // Navigate to native camera fallback
        this.setState({
            navigation: {
                action: 'next',
                props: {
                    fallback: true,
                },
            },
        });
    }

    showInstructionModal() {
        if (this.props.isPoaEnabled) {
            if (
                this.props.context === 'initial' &&
                this.props.docCompleted &&
                this.props.isDocSubmitted &&
                this.props.poaCompleted &&
                this.props.isPoaDocSubmitted
            ) {
                return true;
            }
        }

        if (!this.props.isPoaEnabled) {
            if (
                this.props.docCompleted &&
                this.props.isDocSubmitted &&
                this.props.context === 'initial'
            ) {
                return true;
            }
        }

        return false;
    }

    openCamera() {
        const idliveFaceCapture = document.querySelector('idlive-face-capture');
        idliveFaceCapture.openCamera();
        DataDogService.log('IDR&D Camera Opened');
    }

    /**
     * Configures IDR&D Capture behavior
     * @param {HTMLElement} capture
     */
    configureCapture(capture) {
        // Capture is about to occur
        capture.addEventListener('open', event => {
            this.setState({
                showFrame: true,
            });
        });

        // Face has been detected
        capture.addEventListener('detection', event => {
            if (event.detail[0].errors.length === 0) {
                this.updateMessage('CAPTURING');
            } else {
                this.updateMessage(event.detail[0].errors[0]);
            }
        });

        // Capture is about to occur
        capture.addEventListener('beforeCapture', event => {
            this.updateMessage('CAPTURING');
        });

        // Camera was closed
        capture.addEventListener('close', event => {
            DataDogService.log('IDR&D Camera Closed');
            this.setState({
                showFrame: false,
            });
            this.updateMessage(null);
        });

        // Capture has occurred
        capture.addEventListener('capture', event => {
            // Log to Datadog
            DataDogService.log('IDR&D Liveness Captured');

            // Clear message, show loader
            this.updateMessage(null);
            this.setState({ loading: true });

            // Send result for processing
            const { photo, encryptedFile } = event.detail[0];
            this.processImage(photo, encryptedFile);
        });

        // Error has occurred
        capture.addEventListener('error', event => {
            // Log Error(s)
            event.detail.forEach(error =>
                DataDogService.createError(
                    'IDR&D Liveness error: ' + error.message
                )
            );
            this.fallbackCapture('SDK Error');
        });

        DataDogService.log('IDR&D Camera Configured');
    }

    // Read the image as base64
    processImage(photo, bundle) {
        // If Reusable Profiles, send to legacy endpoint
        if (this.props.context !== 'initial') {
            const reader = new FileReader();
            reader.readAsDataURL(photo);

            reader.onloadend = () => {
                this.submitLivenessImage(reader.result);
            };

            // Otherwise send bundle or binary liveness to new endpoint
        } else {
            if (this.props.vendor === 'idrnd') {
                this.submitLiveness(bundle, 'bundle');
            } else {
                this.submitLiveness(photo, 'image');
            }
        }
    }

    // Translates and updates face detection messages for user
    updateMessage(message) {
        if (message === null) {
            this.setState({ detectionMessage: null });
        } else {
            const { t } = this.props;
            const faceDetectionStates = {
                FACE_NOT_FOUND: 'idpal_move_face',
                TOO_MANY_FACES: 'idpal_too_many_faces',
                FACE_ANGLE_TOO_LARGE: 'idpal_move_face',
                PROBABILITY_TOO_SMALL: 'idpal_move_face',
                FACE_TOO_SMALL: 'idpal_move_closer',
                FACE_CLOSE_TO_BORDER: 'idpal_move_face',
                CAPTURING: 'idpal_capturing',
            };

            this.setState({
                messageClass:
                    message === 'CAPTURING'
                        ? 'liveness-frame--positive'
                        : 'liveness-frame--negative',

                detectionMessage: t(faceDetectionStates[message]),
            });
        }
    }

    // Send Liveness Binary to Liveness endpoint for validation
    submitLiveness(binary, type) {
        clearError(this);
        apiService
            .postPasssiveLiveness(binary, type)
            .then(response => {
                DataDogService.log(
                    'IDR&D Liveness successfully sent for verification.'
                );
                this.props.completeStage('passive_liveness');
                this.setState({
                    navigation: {
                        action: 'next',
                    },
                });
            })
            .catch(error => {
                if (error.status === 403 && !this.state.refreshAttempted) {
                    // Session expired
                    regenerateSession(
                        this.submitLiveness,
                        [binary, type],
                        this
                    );
                } else if (error.status === 420) {
                    // Invalid verification
                    DataDogService.log(
                        'ID-Pal Liveness successfully sent, invalid result. Continuing. '
                    );
                    this.props.completeStage('passive_liveness');
                    this.setState({
                        navigation: {
                            action: 'next',
                        },
                    });
                } else if (error.status === 429) {
                    // Too many previous attempts
                    DataDogService.log(
                        'ID-Pal Liveness successfully sent, too many previous attempts. Continuing. '
                    );
                    this.props.completeStage('passive_liveness');
                    this.setState({
                        navigation: {
                            action: 'next',
                        },
                    });
                } else {
                    // Actual error
                    DataDogService.createError(
                        'Unable to send IDR&D liveness for verification.'
                    );
                    this.setState({ loading: false });
                    logService.error(error);
                    this.setState({
                        navigation: {
                            action: 'error',
                            props: {
                                retryAction: () =>
                                    this.submitLiveness(binary, type),
                                error: error,
                            },
                        },
                    });
                }
            });
    }

    // Send Base64 Image to API for validation - LEGACY
    submitLivenessImage(base64Image) {
        clearError(this);
        const image = sanitiseImage(base64Image);
        apiService
            .getPassiveLivenessResult(image)
            .then(response => {
                DataDogService.log(
                    'IDR&D Liveness successfully sent for verification at legacy endpoint'
                );
                this.props.completeStage('passive_liveness');
                this.setState({
                    navigation: {
                        action: 'next',
                    },
                });
            })
            .catch(error => {
                DataDogService.createError(
                    'Unable to send IDR&D liveness for verification at legacy endpoint.'
                );
                this.setState({ loading: false });
                logService.error(error);
                this.setState({
                    navigation: {
                        action: 'error',
                        props: {
                            retryAction: () =>
                                this.submitLivenessImage(base64Image),
                            error: error,
                        },
                    },
                });
            });
    }

    render() {
        const { t } = this.props;

        if (isSubmissionComplete()) {
            return (
                <>
                    <Header />
                    <StageComplete
                        message={t('idpal_your_submission_is_complete', {
                            company: getCompanyName(this.props.companyName),
                        })}
                        hideContinue={true}
                        hideButton={true}
                    />
                </>
            );
        }

        return (
            <>
                {this.showInstructionModal() && (
                    <InstructionModal
                        heading={t('idpal_document_upload_completed')}
                        message={t('idpal_document_upload_completed_message')}
                        image={livenessInstructionGif}
                        cta={t('idpal_continue')}
                        showCta={true}
                    />
                )}

                <Header />

                {/* Loading state / Processing captured image*/}
                <div
                    className='o-site-wrap instructions'
                    style={{
                        display:
                            this.state.loading && this.state.isSDKInitialized
                                ? 'block'
                                : 'none',
                    }}
                >
                    <div className='u-generic-text  u-text-center u-btm-buffer'>
                        <h1
                            ref={this.primaryFocusRef}
                            tabIndex={0}
                            className='loading-ellipse'
                        >
                            {t('idpal_analysing')}
                            <span className='dot1'>.</span>
                            <span className='dot2'>.</span>
                            <span className='dot3'>.</span>
                        </h1>
                    </div>

                    <div className='u-display-loading u-text-center'>
                        <img
                            alt={imageAlt.loading}
                            src={loadingGif}
                            className='capture'
                        />
                    </div>
                </div>

                {/* Showing instructions to capture image / Initializing SDK state*/}
                <div
                    className='o-site-wrap instructions'
                    style={{
                        display:
                            !this.state.loading ||
                            (this.state.loading && !this.state.isSDKInitialized)
                                ? 'block'
                                : 'none',
                    }}
                >
                    <h1
                        className='u-generic-text  u-text-center'
                        ref={this.primaryFocusRef}
                        tabIndex={0}
                    >
                        {t('idpal_time_for_liveness_test')}
                    </h1>

                    <div className='u-display-selfie u-text-center'>
                        <div className='u-display-img-wrap'>
                            <img
                                alt={imageAlt.selfieInstructions}
                                className='capture selfie-instructions-img'
                                src={livenessSvg}
                            />
                        </div>
                    </div>

                    <p className='u-generic-text  u-text-center'>
                        {t('idpal_passive_liveness_instruction_continued')}
                    </p>

                    <div className='u-generic-text u-text-center'>
                        <CustomButton
                            id={'capture'}
                            className='btn'
                            isDisabled={!this.state.isSDKInitialized}
                            label={
                                this.state.isSDKInitialized
                                    ? t('idpal_continue')
                                    : t('idpal_loading')
                            }
                            handleClick={this.openCamera}
                            actionDataLabel={ACTION.openFrontCameraButton}
                        />
                    </div>
                </div>

                {this.state.showFrame && (
                    <div
                        className={`liveness-frame__container ${this.state.messageClass}`}
                    >
                        <span className='liveness-frame__message'>
                            {this.state.detectionMessage}
                        </span>

                        <div className='liveness-frame__guide'></div>
                    </div>
                )}

                {this.state.renderSDK && (
                    <idlive-face-capture mask_hidden></idlive-face-capture>
                )}

                <Navigator
                    page={'passive_liveness'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        isDocSubmitted: state.config.isDocSubmitted,
        isPoaDocSubmitted: state.config.isPoaDocSubmitted,
        docCompleted:
            state.submissionState.submissionState.document_upload.completed,
        poaCompleted:
            state.submissionState.submissionState.poa_upload.completed,
        isPoaEnabled: state.submissionState.screens.poa_upload.enabled,
        vendor: state.submissionState.screens.passive_liveness.vendor,
        context: state.submissionState.screens.passive_liveness.context,
        companyName: state.config.profile.data.company_branding.name,
    };
}

function mapDispatchToProps(dispatch) {
    const actions = bindActionCreators({ completeStage }, dispatch);
    return { ...actions, dispatch };
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(CaptureSelfie)
);
