const InstructionsIcon = ({fill}) => {
    return (
    <svg viewBox="0 0 328 233" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.9227 24.16H22.0027V22H20.9227H3.08006H2.00006V24.16H2.00011V40.6388V41.7188H4.16011V40.6388V24.16H20.9227Z" fill="black"/>
        <path d="M22.0027 24.16H23.4427V25.6H22.0027V24.16ZM22.0027 22V20.56H23.4427V22H22.0027ZM2.00006 22H0.560062V20.56H2.00006V22ZM2.00006 24.16V25.6H0.560062V24.16H2.00006ZM2.00011 24.16V22.72H3.44011V24.16H2.00011ZM2.00011 41.7188V43.1588H0.560108V41.7188H2.00011ZM4.16011 41.7188H5.60011V43.1588H4.16011V41.7188ZM4.16011 24.16H2.72011V22.72H4.16011V24.16ZM22.0027 25.6H20.9227V22.72H22.0027V25.6ZM23.4427 22V24.16H20.5627V22H23.4427ZM20.9227 20.56H22.0027V23.44H20.9227V20.56ZM3.08006 20.56H20.9227V23.44H3.08006V20.56ZM2.00006 20.56H3.08006V23.44H2.00006V20.56ZM0.560062 24.16V22H3.44006V24.16H0.560062ZM2.00011 25.6H2.00006V22.72H2.00011V25.6ZM0.560108 40.6388V24.16H3.44011V40.6388H0.560108ZM0.560108 41.7188V40.6388H3.44011V41.7188H0.560108ZM4.16011 43.1588H2.00011V40.2787H4.16011V43.1588ZM5.60011 40.6388V41.7188H2.72011V40.6388H5.60011ZM5.60011 24.16V40.6388H2.72011V24.16H5.60011ZM20.9227 25.6H4.16011V22.72H20.9227V25.6Z" fill="#A8A8A8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.15989 212.08V211H1.99989V212.08L1.99989 229.944V231.024H4.15989V231.024H20.639H21.719V228.864H20.639H4.15989L4.15989 212.08Z" fill="black"/>
        <path d="M4.15989 211V209.56H5.59989V211H4.15989ZM1.99989 211H0.559889V209.56H1.99989V211ZM1.99989 231.024L1.99989 232.464H0.559889L0.559889 231.024H1.99989ZM4.15989 231.024H5.59989L5.59989 232.464H4.15989L4.15989 231.024ZM4.15989 231.024H2.71989V229.584H4.15989V231.024ZM21.719 231.024H23.159L23.159 232.464H21.719L21.719 231.024ZM21.719 228.864V227.424H23.159V228.864H21.719ZM4.15989 228.864V230.304H2.71989V228.864H4.15989ZM5.59989 211V212.08H2.71989V211H5.59989ZM1.99989 209.56H4.15989V212.44H1.99989V209.56ZM0.559889 212.08V211H3.43989V212.08H0.559889ZM0.559889 229.944L0.559889 212.08H3.43989L3.43989 229.944H0.559889ZM0.559889 231.024V229.944H3.43989V231.024H0.559889ZM4.15989 232.464H1.99989L1.99989 229.584H4.15989L4.15989 232.464ZM5.59989 231.024V231.024H2.71989V231.024H5.59989ZM20.639 232.464H4.15989L4.15989 229.584H20.639L20.639 232.464ZM21.719 232.464H20.639L20.639 229.584H21.719L21.719 232.464ZM23.159 228.864V231.024H20.279V228.864H23.159ZM20.639 227.424H21.719V230.304H20.639V227.424ZM4.15989 227.424H20.639V230.304H4.15989V227.424ZM5.59989 212.08L5.59989 228.864H2.71989L2.71989 212.08H5.59989Z" fill="#A8A8A8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M307.08 226.559H306V228.719H307.08H324.944H326.024V226.559H326.024V210.08V209H323.864V210.08V226.559H307.08Z" fill="black"/>
        <path d="M306 226.559H304.56V225.119H306V226.559ZM306 228.719V230.159H304.56V228.719H306ZM326.024 228.719H327.464V230.159H326.024V228.719ZM326.024 226.559V225.119H327.464V226.559H326.024ZM326.024 226.559V227.999H324.584V226.559H326.024ZM326.024 209V207.56H327.464V209H326.024ZM323.864 209H322.424V207.56H323.864V209ZM323.864 226.559H325.304V227.999H323.864V226.559ZM306 225.119H307.08V227.999H306V225.119ZM304.56 228.719V226.559H307.44V228.719H304.56ZM307.08 230.159H306V227.279H307.08V230.159ZM324.944 230.159H307.08V227.279H324.944V230.159ZM326.024 230.159H324.944V227.279H326.024V230.159ZM327.464 226.559V228.719H324.584V226.559H327.464ZM326.024 225.119H326.024V227.999H326.024V225.119ZM327.464 210.08V226.559H324.584V210.08H327.464ZM327.464 209V210.08H324.584V209H327.464ZM323.864 207.56H326.024V210.44H323.864V207.56ZM322.424 210.08V209H325.304V210.08H322.424ZM322.424 226.559V210.08H325.304V226.559H322.424ZM307.08 225.119H323.864V227.999H307.08V225.119Z" fill="#A8A8A8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M323.559 40.9224V42.0024H325.719V40.9224V23.0798V21.9998H323.559V21.9999H307.08H306V24.1599H307.08H323.559V40.9224Z" fill="black"/>
        <path d="M323.559 42.0024V43.4424H322.119V42.0024H323.559ZM325.719 42.0024H327.159V43.4424H325.719V42.0024ZM325.719 21.9998V20.5598H327.159V21.9998H325.719ZM323.559 21.9998H322.119V20.5598H323.559V21.9998ZM323.559 21.9999H324.999V23.4399H323.559V21.9999ZM306 21.9999H304.56V20.5599H306V21.9999ZM306 24.1599V25.5999H304.56V24.1599H306ZM323.559 24.1599V22.7199H324.999V24.1599H323.559ZM322.119 42.0024V40.9224H324.999V42.0024H322.119ZM325.719 43.4424H323.559V40.5624H325.719V43.4424ZM327.159 40.9224V42.0024H324.279V40.9224H327.159ZM327.159 23.0798V40.9224H324.279V23.0798H327.159ZM327.159 21.9998V23.0798H324.279V21.9998H327.159ZM323.559 20.5598H325.719V23.4398H323.559V20.5598ZM322.119 21.9999V21.9998H324.999V21.9999H322.119ZM307.08 20.5599H323.559V23.4399H307.08V20.5599ZM306 20.5599H307.08V23.4399H306V20.5599ZM304.56 24.1599V21.9999H307.44V24.1599H304.56ZM307.08 25.5999H306V22.7199H307.08V25.5999ZM323.559 25.5999H307.08V22.7199H323.559V25.5999ZM322.119 40.9224V24.1599H324.999V40.9224H322.119Z" fill="#A8A8A8"/>
        <path d="M206 188C206 210.644 187.644 229 165 229L91 229C68.3563 229 50 210.644 50 188L50 63C50 40.3563 68.3563 22 91 22L165 22C187.644 22 206 40.3563 206 63L206 188Z" fill={fill} fillOpacity="0.25"/>
        <g clipPath="url(#clip0_0_1)">
            <path d="M241.347 65.5181V197.399C241.347 204.292 235.759 209.88 228.866 209.88H98.1335C91.2406 209.88 85.6531 204.292 85.6531 197.399V168.426" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M85.6531 153.866V15.6006C85.6531 8.70762 91.2406 3.12012 98.1335 3.12012H228.866C235.759 3.12012 241.347 8.70762 241.347 15.6006V50.9577" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M105.298 61.9355H221.702" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M105.298 164.113H221.702" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M221.702 95.7388H105.298V81.3955C105.298 79.7002 106.672 78.3257 108.368 78.3257H218.633C220.328 78.3257 221.702 79.7002 221.702 81.3955V95.7388Z" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M221.702 95.7388H105.298V113.152H221.702V95.7388Z" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M221.702 113.152H105.298V130.565H221.702V113.152Z" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M218.633 147.978H108.368C106.672 147.978 105.298 146.604 105.298 144.908V130.565H221.702V144.908C221.702 146.604 220.328 147.978 218.633 147.978Z" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M189.246 79.1577V147.146" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M105.298 22.9058H128.669" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M200.511 185.438H223.882" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M105.298 185.438H128.669" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M105.298 36.4028H150.253" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M208.633 22.9058H222.989" stroke="black" strokeWidth="8.78906" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_0_1">
                <rect width="213" height="213" fill="white" transform="translate(57)"/>
            </clipPath>
        </defs>
    </svg>)

}
export default InstructionsIcon;