/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

// Actions
import { setCaptured } from '../../screens/actions/capturedActions';
import { setRetryLimit } from '../../screens/actions/submissionStateActions';
import { setOverlayDisabled } from '../../screens/actions/configActions';
import { setCapturedDocumentImage } from '../../screens/actions/idPropertiesActions';

// Services
import logService from '../../services/shared/logService';
import DataDogService from '../../services/shared/datadogService';
import {
    bytesToSize,
    calculateImageFileSize,
    isIOS,
} from '../../services/shared/helpers';

// Components
import Header from '../../screens/Header';
import Navigator from '../../screens/Navigator';
import CustomButton from '../../screens/Button';
import InstructionModal from '../../screens/Messages/InstructionModal';
import CaptureCompleteState from './components/CaptureCompleteState';
import OutOfRetiesState from './components/OutOfRetriesState';
import IDPalCameraCapture from './components/IDPalCapture';

// Images
import passportGif from '../../assets/img/loading.gif';
import idGif from '../../assets/gifs/loading_idcard.gif';
import loadingGif from '../../assets/gifs/loading.gif';

// Config
import { ACTION_LABELS } from '../../config/dataDogActionLabels';
import { imageAlt } from '../../config/accessabilityRules';
import { isMobile, isTablet } from 'react-device-detect';
import { history } from '../../store';
import { environment } from '../../config/environment';

// Constants
const ACTION = ACTION_LABELS.acuantCamera;

// Global
let loadingTimer = null;
let fallbackTimer = null;
let localStream = null;
let isMounted = false;

const CANVAS_DIMENSIONS = {
    width: 3584,
    height: 2688,
};

const VERTICAL_CANVAS_DIMENSIONS = {
    width: 2688,
    height: 3584,
};

class IDPalCamera extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.scrollToRef = React.createRef();
        this.state = {
            processing: false,
            isCameraInit: false,
            deviceId: '',
            receivedImage: false,
            isOverlayImageLoaded: false,
            isNativeImageLoaded: false,
            flashClassName: '',
            flashed: false,
            overlayError: false,
            fallbackTimeLimit: 5000, //fallback time in ms
            nativeImage: null,
            downscaledNativeImage: null,
            overlayImage: null,
            imageHeight: CANVAS_DIMENSIONS.height,
            imageWidth: CANVAS_DIMENSIONS.width,
            showModal: false,
            maxFileSize: 5.0, // Size in MB
            constraints: {
                video: {
                    facingMode: { exact: 'environment' },
                    height: { ideal: 1440 },
                    width: { ideal: 1440 },
                },
            },
            navigation: {
                action: 'load',
                props: null,
            },
        };

        this.getCameraID = this.getCameraID.bind(this);
        this.flashCamera = this.flashCamera.bind(this);
        this.playOverlayCamera = this.playOverlayCamera.bind(this);
        this.prepareNativeCapture = this.prepareNativeCapture.bind(this);
        this.nativeCapture = this.nativeCapture.bind(this);
        this.isTwoPagePassport = this.isTwoPagePassport.bind(this);
        this.getDimensions = this.getDimensions.bind(this);
    }

    componentDidMount() {
        isMounted = true;
        if (
            this.props.submissionAttempts.remaining > 0 ||
            this.props.sidesLeft > 0 ||
            this.props.submissionAttempts[
                this.props.sidesLeft === 2 ? 'front' : 'back'
            ] === 0
        ) {
            this.clearLoading();
            if (this.props.idpalOverlaySupported && (isMobile || isTablet)) {
                this.scrollToElement();
                isIOS() ? this.initiateOverlayCamera() : this.getCameraID();
                this.detectOrientation();
            } else {
                this.prepareNativeCapture();
                this.initiateNativeCapture();
            }
        }

        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Set document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_capture_id');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        const { t } = this.props;
        if (this.state.overlayError) {
            document.title = t('idpal_doc_title_capture_id_error');
        } else {
            document.title = t('idpal_doc_title_capture_id');
        }

        // Triggers camera stream when camera id is required
        if (prevState.isCameraInit !== this.state.isCameraInit) {
            if (this.state.isCameraInit) {
                this.initiateOverlayCamera();
            }
        }

        // Triggers capture after flash animation
        if (prevState.flashed !== this.state.flashed) {
            if (this.state.flashed) {
                this.captureOverlayImage();
            }
        }

        // Triggers location change after overlay image is loaded
        if (
            prevState.isOverlayImageLoaded !== this.state.isOverlayImageLoaded
        ) {
            if (this.state.overlayImage !== null) {
                this.proceedWithOverlayImage();
            }
        }

        // Triggers location change after native camera image is loaded
        if (this.state.isNativeImageLoaded !== prevState.isNativeImageLoaded) {
            if (this.state.nativeImage) {
                this.proceedWithImage();
            }
        }
    }

    componentWillUnmount() {
        isMounted = false;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    /*
        The back button is triggered if no image is selected
        and the user is brought back to the instructions screen.
        This prevents the user from staying on an empty screen.
    */
    smoothLoading() {
        if (!loadingTimer) {
            const loader = document.getElementById('loader');
            const retry = document.getElementById('retry');
            if (loader && loader.classList) {
                loader.classList.remove('hidden');
            }
            if (retry && retry.classList) {
                retry.classList.add('hidden');
            }

            loadingTimer = setTimeout(() => {
                document.body.onfocus = null;
                const backButton = document.getElementById('back');
                if (backButton) {
                    backButton.click();
                }
            }, 1000);
        }
    }

    clearLoading() {
        document.body.onfocus = null;
        const loader = document.getElementById('loader');
        if (loader) {
            loader.classList.add('hidden');
        }
        clearTimeout(loadingTimer);
        loadingTimer = null;
    }

    setProcessing(value) {
        this.setState({ processing: value });
    }

    handleCancel() {
        if (!this.state.receivedImage) {
            this.goBack();
        }
    }

    goBack() {
        this.setState({
            navigation: {
                action: 'back',
                props: {
                    documentId: this.props.cardType,
                    sidesLeft: this.props.sidesLeft,
                },
            },
        });
    }

    isTwoPagePassport() {
        return (
            (this.props.cardType === 1 && this.props.onePagePassport === 0) ||
            this.props.isExpiredTwoPagePassport
        );
    }

    getDimensions() {
        if (this.isTwoPagePassport()) {
            return VERTICAL_CANVAS_DIMENSIONS;
        } else {
            return CANVAS_DIMENSIONS;
        }
    }

    // Calculate if image file-size is above the max-file size & log size to datadog
    isLargeImage(image) {
        const imageSizData = bytesToSize(calculateImageFileSize(image));
        DataDogService.log(
            `Captured Image Size: ${imageSizData.size} ${imageSizData.unit}`
        );
        logService.log(
            `Captured Image Size: ${imageSizData.size} ${imageSizData.unit}`
        );

        if (imageSizData.unit === 'GB') {
            return true;
        }
        if (
            imageSizData.unit === 'MB' &&
            imageSizData.size > this.state.maxFileSize
        ) {
            return true;
        }
        return false;
    }

    /**
     * Overlay functionality starts here
     **/

    initiateOverlayCamera() {
        const canvas = document.querySelector('#my-canvas');
        this.playOverlayCamera(canvas);
    }

    getCameraID() {
        const devices = navigator.mediaDevices;
        if (devices && 'getUserMedia' in devices) {
            if (!devices?.enumerateDevices) {
                logService.error('enumerateDevices() not supported.');
                this.prepareNativeCapture();
            } else {
                let videoInputIDs = null;
                devices
                    .enumerateDevices()
                    .then(devices => {
                        // Select only video inputs
                        videoInputIDs = devices.filter(device => {
                            if (device.kind === 'videoinput') {
                                return device.deviceId;
                            } else {
                                return null;
                            }
                        });

                        /*
                        Set the device ID to the last available video input
                        For devices with multiple cameras this is the standard environment facing camera.
                    */
                        videoInputIDs.length !== 0 &&
                            this.setState({
                                constraints: {
                                    video: {
                                        ...this.state.video,
                                        width: { ideal: this.state.imageWidth },
                                        height: {
                                            ideal: this.state.imageHeight,
                                        },
                                        deviceId: {
                                            exact: videoInputIDs[
                                                videoInputIDs.length - 1
                                            ].deviceId,
                                        },
                                    },
                                },
                            });
                        this.setState({ isCameraInit: true });
                    })
                    .catch(error => {
                        logService.error(`${error.name}: ${error.message}`);
                        this.prepareNativeCapture();
                    });
            }
        }
    }

    playOverlayCamera(canvas) {
        const devices = navigator.mediaDevices;
        if (devices && 'getUserMedia' in devices) {
            const promise = devices.getUserMedia(this.state.constraints);
            promise
                .then(
                    function (stream) {
                        fallbackTimer = setTimeout(
                            this.prepareNativeCapture,
                            this.state.fallbackTimeLimit
                        );
                        const video = document.getElementById('my-video');
                        video.addEventListener('loadedmetadata', function () {
                            const context = canvas.getContext('2d');
                            const drawFrame = function () {
                                clearTimeout(fallbackTimer);
                                const width = CANVAS_DIMENSIONS.width;
                                const height =
                                    (width / video.videoWidth) *
                                    video.videoHeight;
                                context.clearRect(
                                    0,
                                    0,
                                    canvas.width,
                                    canvas.height
                                );
                                context.drawImage(video, 0, 0, width, height);

                                isMounted && requestAnimationFrame(drawFrame);
                            };
                            isMounted && drawFrame();
                        });
                        DataDogService.log(
                            'Capturing with ID Pal capture overlay.'
                        );
                        video.autoplay = true;
                        video.srcObject = stream;
                        localStream = stream;
                    }.bind(this)
                )
                .catch(
                    function (error) {
                        this.prepareNativeCapture();
                        logService.error(`${error.name}: ${error.message}`);
                    }.bind(this)
                );
        } else {
            this.prepareNativeCapture();
            logService.error('Camera API is not supported.');
        }
    }

    captureOverlayImage() {
        // Stop the camera stream if running
        if (localStream && localStream?.getVideoTracks()) {
            const tracks = localStream.getVideoTracks();
            tracks[0].stop();
            localStream = null;
        }

        // Capture the image
        const canvas = document.querySelector('#my-canvas');
        const image = canvas.toDataURL('image/jpeg');
        this.setState({ overlayImage: image });
        this.setState({ isOverlayImageLoaded: true });

        // Clear the video src
        const video = document.getElementById('my-video');
        video.srcObject = null;
        DataDogService.log(
            'Image successfully captured with ID Pal capture overlay'
        );
    }

    proceedWithOverlayImage() {
        // First check if we are submitting with a large image
        if (this.isLargeImage(this.state.overlayImage)) {
            DataDogService.log('Submitting with large image');
        }

        // Save the image to redux
        this.props.setCapturedDocumentImage(
            this.state.overlayImage,
            'uncropped'
        );

        const properties = {
            imageSize: 123, // Placeholders. Either remove, or get proper values
            width: CANVAS_DIMENSIONS.width,
            height: CANVAS_DIMENSIONS.height,
            originalWidth: CANVAS_DIMENSIONS.width,
            originalHeight: CANVAS_DIMENSIONS.height,
            properties: {
                sharpness: 100, // Placeholders. Either remove, or get proper values
                glare: 100, // Placeholders. Either remove, or get proper values
                dpi: 500, // Placeholders. Either remove, or get proper values
                documentId: this.props.location.state.documentId,
                isPassport: 1, // Placeholders. Either remove, or get proper values
            },
        };

        DataDogService.log('Proceeding with ID Pal overlay image.');
        this.setState({
            navigation: {
                action: 'next',
                props: properties,
            },
        });
    }

    // Delays capturing the image until camera flash animation has started
    flashCamera() {
        this.setState({ flashClassName: 'flash' });
        setTimeout(() => {
            // Triggers capture through state change
            this.setState({ flashed: true });
        }, 500);
    }

    detectOrientation() {
        window
            .matchMedia('(orientation: portrait)')
            .addEventListener('change', e => {
                const portrait = e.matches;
                if (portrait) {
                    this.setState({ showModal: false });
                } else {
                    this.setState({ showModal: true });
                }
            });
    }

    // Scrolls to the first h1 tag to ensure cta is visible on all devices
    scrollToElement() {
        this.scrollToRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    /**
     * Native capture functionality starts here
     **/

    initiateNativeCapture() {
        this.setState({
            overlayError: false,
        });
        const nativeCameraInput = document.getElementById('readUrl');

        if (nativeCameraInput) {
            nativeCameraInput.click();
        }
    }

    prepareNativeCapture() {
        // Stop the camera stream if running
        if (localStream && localStream?.getVideoTracks()) {
            const tracks = localStream.getVideoTracks();
            tracks[0].stop();
            localStream = null;
        }

        const video = document.getElementById('my-video');

        if (video) {
            video.srcObject = null;
        }

        // The first time on mobile, show error
        if (
            this.props.idpalOverlaySupported === true &&
            (isMobile || isTablet)
        ) {
            this.setState({
                overlayError: true,
            });
        }
        this.props.setOverlayDisabled();
    }

    // Downscale the image to below the MaxWidth or MaxHeight based on the aspect ratio.
    downscaleImage(image) {
        DataDogService.log('Large image submitted. Image was Downscaled.');

        let width = image.width;
        let height = image.height;
        const MAX_WIDTH = 3584; // taken from the MAX_WIDTH property in the acuant SDK
        const MAX_HEIGHT = 2688; // taken from the MAX_HEIGHT property in the acuant SDK

        // Change the resizing logic based on the initial aspect ratio
        if (width > height) {
            if (width > MAX_WIDTH) {
                height = height * (MAX_WIDTH / width);
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width = width * (MAX_HEIGHT / height);
                height = MAX_HEIGHT;
            }
        }

        // Uses hidden canvas to downscale the image
        const hiddenCanvas = document.querySelector('#hiddenCanvas');
        hiddenCanvas.width = width;
        hiddenCanvas.height = height;
        const ctx = hiddenCanvas.getContext('2d');

        // Settings taken from the acuant SDK to ensure sharpness is retained
        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.msImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(image, 0, 0, width, height);
        const downscaled = hiddenCanvas.toDataURL('image/jpeg');
        return downscaled;
    }

    nativeCapture() {
        const self = this;

        DataDogService.log('Capturing with ID Pal capture native camera.');
        // Sets the focus to allow loading animation and back navigation if the camera is closed
        document.body.onfocus = this.smoothLoading;

        // Gets the image from the camera
        document
            .getElementById('readUrl')
            .addEventListener('change', function () {
                if (this.files[0]) {
                    const picture = new FileReader();
                    picture.readAsDataURL(this.files[0]);
                    DataDogService.log(
                        'Image successfully captured with ID Pal capture native camera'
                    );
                    picture.addEventListener('load', function (event) {
                        const image = new Image();
                        image.src = event.target.result;
                        self.setState({ receivedImage: true });
                        self.setState({ nativeImage: event.target.result });
                        self.clearLoading();
                        image.onload = function () {
                            // If image above the maxWith or maxHeight downscale to the same size as the overlay image
                            if (
                                this.width > environment.maxWidth ||
                                this.height > environment.maxHeight
                            ) {
                                self.setState({
                                    downscaledNativeImage:
                                        self.downscaleImage(this),
                                });
                            }
                            self.setState({ imageWidth: this.width });
                            self.setState({ imageHeight: this.height });
                            self.setState({ isNativeImageLoaded: true });
                        };
                    });
                } else {
                    logService.error('No image uploaded');
                }
            });
    }

    proceedWithImage() {
        // First log if we are submitting with a large image
        if (this.isLargeImage(this.state.nativeImage)) {
            DataDogService.log('Submitting with large image');
        }

        // Check if the image should be downscaled.
        if (this.state.downscaledNativeImage) {
            this.props.setCapturedDocumentImage(
                this.state.downscaledNativeImage,
                'downscaled'
            );
        } else {
            this.props.setCapturedDocumentImage(
                this.state.nativeImage,
                'uncropped'
            );
        }

        const properties = {
            imageSize: 123, // Placeholders. Either remove, or get proper values
            width: this.state.imageWidth,
            height: this.state.imageHeight,
            originalWidth: this.state.imageWidth,
            originalHeight: this.state.imageHeight,
            properties: {
                sharpness: 100, // Placeholders. Either remove, or get proper values
                glare: 100, // Placeholders. Either remove, or get proper values
                dpi: 500, // Placeholders. Either remove, or get proper values
                documentId: this.props.location.state.documentId,
                isPassport: 1,
            },
        };

        DataDogService.log('Proceeding with ID Pal native camera image.');

        this.setState({
            navigation: {
                action: 'next',
                props: properties,
            },
        });
    }
    
    render() {
        const { t } = this.props;
        const analyzingGif = this.props.cardType === 1 ? passportGif : idGif;

        // Document upload is already completed
        if (this.props.completed) {
            return (
                <CaptureCompleteState 
                    navigateNext={ () =>
                        this.setState({ navigation: { action: 'next' } })
                    }
                    navigation={this.state.navigation}
                />
            );
        }

        // Out of retries, and we've already tried this side
        if (
            this.props.submissionAttempts.remaining <= 0 &&
            this.props.sidesLeft === 0 &&
            this.props.submissionAttempts[
                this.props.sidesLeft === 2 ? 'front' : 'back'
            ] > 0
        ) {
            return (
                <OutOfRetiesState
                    navigateNext={() => {
                        history.goForward();
                    }}
                    navigation={this.state.navigation}
                />
            );
        }

        return (
            <Fragment>
                <div className={this.state.flashClassName} />
                {this.state.showModal && (
                    <InstructionModal
                        heading={t('idpal_change_orientation')}
                        message={t('idpal_change_orientation_message')}
                        showCta={false}
                        isRotated={true}
                    />
                )}

                {!this.props.idpalOverlaySupported && <Header />}

                <div
                    id={'loader'}
                    className='u-text-center start-loading hidden'
                >
                    <img
                        alt={imageAlt.loading}
                        src={loadingGif}
                        className='capture'
                    />
                </div>

                {this.props.idpalOverlaySupported && (isMobile || isTablet) && (
                    <IDPalCameraCapture
                        flashCamera={this.flashCamera}
                        getDimensions={this.getDimensions}
                        isTwoPagePassport = {this.isTwoPagePassport}
                        primaryFocusRef = {this.primaryFocusRef}
                        scrollToRef = {this.scrollToRef}
                    />
                   
                )}

                {!this.props.idpalOverlaySupported && (
                    <Fragment>
                        <div className='o-site-wrap'>
                            <div className='o-entryBtns'>
                                {this.state.overlayError === true && (
                                    <Fragment>
                                        <h1
                                            className='u-generic-text  u-text-center u-btm-buffer'
                                            ref={this.primaryFocusRef}
                                            tabIndex={0}
                                        >
                                            {t('idpal_camera_failed_to_open')}
                                        </h1>
                                        <CustomButton
                                            id={'open_native_camera'}
                                            className={'btn hidden'}
                                            label={t('idpal_open_camera')}
                                            handleClick={() =>
                                                this.initiateNativeCapture()
                                            }
                                        />
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}

                {!this.state.processing && (
                    <div id={'loading'} className='o-site-wrap hidden'>
                        <div className='u-display-analysing u-text-center hidden'>
                            <CustomButton
                                id={'back'}
                                className={'btn hidden'}
                                handleClick={() => this.handleCancel()}
                                actionDataLabel={ACTION.backHiddenButton}
                            />
                        </div>
                    </div>
                )}

                {this.state.processing && (
                    <div
                        className={
                            this.state.processing
                                ? 'o-site-wrap'
                                : 'o-site-wrap'
                        }
                    >
                        <h1
                            className='u-generic-text u-text-center u-btm-buffer loading-ellipse'
                            ref={this.primaryFocusRef}
                            tabIndex={0}
                        >
                            {t('idpal_uploading')}
                            <span className='dot1'>.</span>
                            <span className='dot2'>.</span>
                            <span className='dot3'>.</span>
                        </h1>
                        <div className='u-display-analysing u-text-center'>
                            <img
                                alt={imageAlt.idCardLoading}
                                src={analyzingGif}
                                className='capture'
                            />
                        </div>
                    </div>
                )}

                <input
                    className='hidden'
                    type='file'
                    id='readUrl'
                    accept='image/*'
                    capture
                    onClick={() => this.nativeCapture()}
                />
                {/* Hidden canvas for downscaling image */}
                <canvas className='hidden' id='hiddenCanvas'></canvas>
                <Navigator
                    page={'document_capture'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        cardType: state.idProperties.cardType,
        submissionAttempts: state.submissionState.submissionAttempts,
        completed:
            state.submissionState.submissionState.document_upload.completed,
        cropSettings: state.config.cropSettings,
        sidesLeft: state.idProperties.sidesLeft,
        idpalOverlaySupported: state.config.idpalOverlaySupported,
        onePagePassport:
            state.submissionState.screens.document_upload.one_page_passport,
        isExpiredTwoPagePassport: state.idProperties.isExpiredTwoPagePassport,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setCaptured,
            setRetryLimit,
            setOverlayDisabled,
            setCapturedDocumentImage,
        },
        dispatch
    );
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(IDPalCamera)
);
