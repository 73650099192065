import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/CustomButton';
import Header from '../../../components/Header';

const IDPalCameraCapture = ({
    flashCamera,
    getDimensions,
    isTwoPagePassport = false,
    primaryFocusRef,
}) => {
    const { t } = useTranslation();

    return (
        <>
<div className='capture-container root-layout-container id-pal-overlay'>
    <Header />
    <h1 ref={primaryFocusRef} className='id-pal-overlay-title' tabIndex={0}>
            {isTwoPagePassport()
                ? t("idpal_one_page_passport_instructions")
                : t("idpal_two_page_passport_instructions")}
    </h1>

    <div
        className={
            isTwoPagePassport()
                ? 'capture-content vertical'
                : 'capture-content'
        }
    >

        <div className='content-capture'>
            <div className='canvas-container'>
                <div className='capture-brackets'/>
                <div className='capture-guide'/>
                <video
                    id='my-video'
                    controls
                    autoPlay
                    playsInline
                    style={{ display: 'none' }}
                ></video>
                <canvas
                    id='my-canvas'
                    height={getDimensions().height}
                    width={getDimensions().width}
                ></canvas>
            </div>
        </div>

        <div className='content-message'>
            <p className='u-generic-text u-text-center'>
                {isTwoPagePassport()
                    ? t('idpal_two_page_passport_instructions')
                    : t('idpal_camera_capture_message')}
            </p>
        </div>
    </div>

    <div className='content-footer'>
        <div className='btn-container'>
            <CustomButton
                handleClick={() => flashCamera()}
                actionDataLabel={'Id-pal-capture'}
                label={t('idpal_capture')}
                className={'btn'}
            />
        </div>
    </div>
</div>
</>
);
}
export default IDPalCameraCapture;