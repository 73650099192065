// Images
import loadingGif from '../../../assets/gifs/loading.gif';
import { imageAlt } from '../../../config/accessabilityRules';
import { useTranslation } from 'react-i18next';

const LoadingState = ({primaryFocusRef}) => {
    const { t } = useTranslation();
    return (    
    <div className='o-site-wrap instructions'>
        <div className='u-generic-text  u-text-center u-btm-buffer'>
            <h1
                ref={primaryFocusRef}
                tabIndex={0}
                className='loading-ellipse'
            >
                {t('idpal_analysing')}
                <span className='dot1'>.</span>
                <span className='dot2'>.</span>
                <span className='dot3'>.</span>
            </h1>
        </div>

        <div className='u-display-loading u-text-center'>
            <img
                alt={imageAlt.loading}
                src={loadingGif}
                className='capture'
            />
        </div>
    </div>
    )
};
export default LoadingState;