/**
 * @prettier
 */

import ApiService from '../../services/api/api';
import i18next from 'i18next';
import logService from '../../services/shared/logService';
import DataDogService from '../../services/shared/datadogService';

export function initialiseEVerification(data) {
    return dispatch => {
        dispatch({
            type: 'RESET_E_VERIFICATION',
        });

        dispatch(
            {
                type: 'ADDRESS_LOOKUP_ACTIVE',
                data: data['address_lookup'],
            },
            dispatch({
                type: 'RESET_ADDRESS_LOOKUP',
            })
        );

        let customFields = data['custom_fields'];
        let customFieldArray = [];

        let activatedCountries = data['activated_countries'];

        // Build custom field rules
        for (let i = 0; i < customFields.length; i++) {
            let field = {
                name: customFields[i].field_name,
                id: 'customField',
                min: customFields[i].min_length,
                max: customFields[i].max_length,
                regex: customFields[i].regex,
                required: customFields[i].is_required,
                iso: customFields[i].iso_3166_3,
                countryName: customFields[i].country_name,
                messages: {
                    regex: i18next.t('idpal_invalid_regex_error', {
                        field: customFields[i].field_name,
                    }),
                },
            };
            customFieldArray.push(field);
        }
        dispatch(
            {
                type: 'SET_E_VERIFICATION_CUSTOM_FIELDS',
                data: customFieldArray,
            },
            dispatch(
                {
                    type: 'SET_ACTIVATED_COUNTRIES',
                    data: activatedCountries,
                },
                dispatch({
                    type: 'SET_E_VERIFICATION_MODE',
                    data: data['mode'],
                })
            )
        );
    };
}

export function submitEVerification(data, context) {
    return dispatch => {
        dispatch({ type: 'PENDING' });

        ApiService.postEVerification(data)
            .then(response => {
                dispatch({ type: 'COMPLETE' });

                // Retrieve response data
                let addressVerification = response.data.addressVerification;
                if (addressVerification.hasOwnProperty('result')) {
                    // Save result to submissionState
                    dispatch({
                        type: 'SET_E_VERIFICATION_RESULT',
                        data: addressVerification.result,
                    });
                } else {
                    // Save error to submissionState
                    dispatch({
                        type: 'SET_E_VERIFICATION_RESULT',
                        data: 'error',
                    });
                }
            })
            .catch(error => {
                DataDogService.createError(
                    'Unable to send address for verification.'
                );
                dispatch({ type: 'COMPLETE' });
                logService.error(error);
                context.setState({
                    navigation: {
                        action: 'error',
                        props: {
                            retryAction: () => context.handleSubmit(data),
                            error: error,
                        },
                    },
                });
            });
    };
}
export function storeAddress(data, context) {
    return dispatch => {
        dispatch({
            type: 'PENDING',
        });
        ApiService.postStoreAddress(data)
            .then(response => {
                dispatch({
                    type: 'COMPLETE',
                });

                // Save result to submissionState
                dispatch({
                    type: 'SET_E_VERIFICATION_RESULT',
                    data: 'stored',
                });
            })
            .catch(error => {
                DataDogService.createError(
                    'Unable to send address for storage.'
                );
                dispatch({ type: 'COMPLETE' });
                logService.error(error);
                context.setState({
                    navigation: {
                        action: 'error',
                        props: {
                            retryAction: () => context.handleSubmit(data),
                            error: error,
                        },
                    },
                });
            });
    };
}

export function submitEVerificationV2(data, handleErrorCallback) {
    return dispatch => {
        dispatch({ type: 'PENDING' });

        ApiService.postEVerification(data)
            .then(response => {
                dispatch({ type: 'COMPLETE' });

                // Retrieve response data
                const addressVerification = response.data.addressVerification;
                if (addressVerification.hasOwnProperty('result')) {
                    // Save result to submissionState
                    dispatch({
                        type: 'SET_E_VERIFICATION_RESULT',
                        data: addressVerification.result,
                    });
                } else {
                    // Save error to submissionState
                    dispatch({
                        type: 'SET_E_VERIFICATION_RESULT',
                        data: 'error',
                    });
                }
            })
            .catch(error => {
                DataDogService.createError(
                    'Unable to send address for verification.'
                );
                dispatch({ type: 'COMPLETE' });
                logService.error(error);
                handleErrorCallback(error, data);
            });
    };
}

export function storeAddressV2(data, handleErrorCallback) {
    return dispatch => {
        dispatch({
            type: 'PENDING',
        });
        ApiService.postStoreAddress(data)
            .then(response => {
                dispatch({
                    type: 'COMPLETE',
                });

                // Save result to submissionState
                dispatch({
                    type: 'SET_E_VERIFICATION_RESULT',
                    data: 'stored',
                });
            })
            .catch(error => {
                DataDogService.createError(
                    'Unable to send address for storage.'
                );
                dispatch({ type: 'COMPLETE' });
                logService.error(error);
                handleErrorCallback(error, data);              
            });
    };
}

export function setFormValueV2(id, value) {       
    return dispatch => {
        dispatch({
            type: 'SET_FORM_VALUE',
            data: {
                id,
                value
            },
        });
    };
}

export function getCountries() {
    return dispatch => {
        dispatch({
            type: 'PENDING',
        });
        ApiService.getCountries().then(response => {
            dispatch({
                type: 'COMPLETE',
            });

            if (response.code === 200) {
                dispatch({
                    type: 'SET_COUNTRIES',
                    data: response.data.countries,
                });
            }
        });
    };
}

export function setPhone(value, data, event, formattedValue) {    
    if (data.dialCode) {
        if (
            value.slice(0, data.dialCode.length) !== data.dialCode &&
            value.length > 5
        ) {
            value = data.dialCode + value;
        }
        return dispatch => {
            dispatch(
                {
                    type: 'SET_FORM_VALUE',
                    data: {
                        id: 'phone',
                        value: value,
                    },
                },
            dispatch(
                {
                    type: 'SET_FORM_VALUE',
                    data: {
                        id: 'phonePrefix',
                        value: data.dialCode,
                    },
                },
            dispatch({
                type: 'SET_FORM_VALUE',
                data: {
                    id: 'phoneRaw',
                    value: value.slice(data.dialCode.length),
                },
            })
            )
        );
    };
    } else {
        return dispatch => {
            dispatch({
                type: 'SET_FORM_VALUE',
                data: {
                    id: 'phone',
                    value: null,
                },
            });
        };
    }
}

export function setFormValue(event) { 
    return dispatch => {
        dispatch({
            type: 'SET_FORM_VALUE',
            data: {
                id: event.target.id,
                value: event.target.value,
            },
        });
    };
}

export function setSelectedCountry(code, countries) {
    let selectedCountry = countries.find(o => o.iso_3166_3 === code);
    return dispatch => {
        dispatch({
            type: 'SET_SELECTED_COUNTRY',
            data: {
                selectedCountry: selectedCountry,
            },
        });
    };
}

export function setErrors(errors) {
    return dispatch => {
        dispatch({
            type: 'SET_EVERIFICATION_ERRORS',
            data: {
                errors: errors,
            },
        });
    };
}

export function setAutoAddressFocus(isAutoAddressFocus) {
    return dispatch => {
        dispatch({
            type: 'SET_AUTO_ADDRESS_FOCUS',
            data: isAutoAddressFocus,
        });
    };
}

export function setDateErrorFocus(isErrorFocus) {
    return dispatch => {
        dispatch({
            type: 'SET_IS_ERROR_FOCUS',
            data: isErrorFocus,
        });
    };
}
