import { useEffect, useState } from 'react';
import { ModalPopupOverlay } from '../../../components/ModalPopupOverlay';
import { useTranslation } from 'react-i18next';
import PrepareToUploadPOAModalPopupIcon from './DynamicColoredIcons/PrepareToUploadPOAModalPopupIcon';
import FailedToUploadPOAIcon from './DynamicColoredIcons/FailedToUploadPOAIcon';
const ActiveModalOverlayState = ({
    showDocumentUploadModal,
    isInvalidFileSize = false,
    isInvalidFileType = false,
    showModal = false,
}) => {
    const { t } = useTranslation();
    const [showFileSizeExceedModal, toggleFileSizeExceedModalPopup] = useState(false);
    const [showUnsupportedFileTypeModal, toggleUnsupportedFileTypeModalPopup] = useState(false);

    useEffect(() => {
        if(showModal && (isInvalidFileType || isInvalidFileSize)) {
            toggleFileSizeExceedModalPopup(false);
            toggleUnsupportedFileTypeModalPopup(false);
            if(isInvalidFileType) {
                toggleUnsupportedFileTypeModalPopup(true);
            } else {
                toggleFileSizeExceedModalPopup(true);
            }
        }
    },[showModal, isInvalidFileType, isInvalidFileSize]);
    return (
        <>  
             {showDocumentUploadModal() && <ModalPopupOverlay
                Icon={PrepareToUploadPOAModalPopupIcon}
                popupTitle={t("idpal_document_upload_completed")}
                popupText={t("idpal_document_upload_completed_poa_message")}
            />}
            {
                showModal && (
                    <>
                        {showUnsupportedFileTypeModal && <ModalPopupOverlay
                            Icon={FailedToUploadPOAIcon}
                            popupTitle={t("idpal_popup_title_doc_not_supported")}
                            popupText={t("idpal_popup_text_doc_not_supported")}
                        />}
                        {showFileSizeExceedModal && <ModalPopupOverlay
                            Icon={FailedToUploadPOAIcon}
                            popupTitle={t("idpal_popup_title_doc_too_large")}
                            popupText={t("idpal_popup_text_doc_too_large")}
                        />}
                    </>
                )
            }
        </>
    )
};
export default ActiveModalOverlayState;