/**
 * @prettier
 */

export function eVerificationReducer(state = {}, action) {
    switch (action.type) {
        case 'SET_COUNTRIES':
            return {
                ...state,
                countries: action.data.countries,
                selectedCountry: action.data.current_country,
                initialCountry: action.data.current_country.iso_3166_2,
            };
        case 'SET_FORM_VALUE':
            return {
                ...state,
                [action.data.id]: action.data.value,
            };
        case 'SET_E_VERIFICATION_CUSTOM_FIELDS':
            return {
                ...state,
                customFields: action.data,
            };
        case 'SET_ACTIVATED_COUNTRIES':
            return {
                ...state,
                activatedCountries: action.data,
            };
        case 'SET_SELECTED_COUNTRY':            
            return {
                ...state,
                selectedCountry: action.data.selectedCountry,
            };
        case 'SET_ADDRESS_FROM_LOOKUP':
            return {
                ...state,
                address1: action.data.address1,
                address2: action.data.address2,
                city: action.data.locality,
                postCode: action.data.postalCode,
            };
        case 'ADDRESS_SUBMITTED':
            return {
                ...state,
                addressSubmitted: true,
            };
        case 'SET_EVERIFICATION_ERRORS':
            return {
                ...state,
                errors: action.data.errors,
            };
        case 'SET_AUTO_ADDRESS_FOCUS':
            return {
                ...state,
                isAutoAddressFocus: action.data,
            };
        case 'SET_IS_ERROR_FOCUS':
            return {
                ...state,
                isDateErrorFocus: action.data,
            };
        case 'SET_E_VERIFICATION_MODE':
            return {
                ...state,
                mode: action.data,
            };
        case 'RESET_E_VERIFICATION':
            return {
                ...state,
                mode: 'document_fallback',
                addressSubmitted: false,
                enabled: false,
                customFields: [],
                activatedCounties: [],
                countries: [],
                selectedCountry: {
                    name: '',
                    iso_3166_3: '',
                    iso_3166_2: '',
                    supports_address_lookup: false,
                },
                initialCountry: null,
                firstName: null,
                lastName: null,
                dob: null,
                address1: '',
                address2: '',
                city: '',
                postCode: '',
                phone: null,
                customField: null,
                errors: {},
            };

        default:
            return state;
    }
}
