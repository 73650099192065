import Header from "../../../components/Header";
import OutOfRecaptures from "../../../screens/Messages/OutOfRecaptures";
import Navigator from "../../../screens/Navigator";

const OutOfRetiesState = ({navigation, navigateNext}) => {
    return (
         <>
            <Header />
            <OutOfRecaptures
                continue={navigateNext}
                message='idpal_out_of_attempts'
            />
            <Navigator
                page={'document_capture'}
                action={navigation.action}
                propsToPass={navigation.props}
            />
        </>
    )
}
export default OutOfRetiesState;