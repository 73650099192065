/**
 * @prettier
 */

// React Packages
import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { setInstanceID } from './actions/configActions';

const Checkbox = ({ branding = null, ...props }) => {
    const [colorState, setColorState] = useState(null);

    const handleChange = e => {
        props.handleChange(e);
    };
    useEffect(() => {
        if (branding) {
            setColorState(
                branding.data.company_branding.primary_color
                    ? branding.data.company_branding.primary_color
                    : colorState
            );
        }
    }, [branding, colorState]);

    const checkboxStyles = useMemo(() => {
        return {
            borderColor: `#${colorState}`,
        };
    }, [colorState]);

    return (
        <input
            type='checkbox'
            id={props.id}
            style={checkboxStyles}
            name={props.name}
            className={props.className}
            onChange={handleChange}
            data-dd-action-name={props.actionDataLabel}
        ></input>
    );
};
function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        branding: state.config.profile,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setInstanceID }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
