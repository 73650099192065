const LowResolutionWarningIcon = ({ fill="#0047FF" }) => (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32.5" cy="32.5" r="32.5" fill={fill} fillOpacity="0.24"/>
    <path d="M3 62H29.333C29.379 62 29.418 61.98 29.462 61.974C29.506 61.98 29.545 62 29.591 62H34.409C34.961 62 35.409 61.552 35.409 61C35.409 60.448 34.961 60 34.409 60H30.333V34.667C30.333 34.115 29.885 33.667 29.333 33.667H4V29.592C4 29.04 3.552 28.592 3 28.592C2.448 28.592 2 29.04 2 29.592V34.41C2 34.456 2.02 34.495 2.026 34.539C2.02 34.583 2 34.622 2 34.668V61.001C2 61.553 2.448 62 3 62ZM24.773 60H4V52.736L10.101 45.22L15.632 52.03L11.801 56.75C11.453 57.179 11.518 57.809 11.947 58.157C12.377 58.505 13.006 58.44 13.354 58.011L20.696 48.965L28.334 58.378V60.001L24.773 60ZM28.333 55.204L21.471 46.748C21.092 46.279 20.298 46.281 19.918 46.748L16.919 50.443L10.876 43.003C10.496 42.535 9.703 42.535 9.323 43.003L3.999 49.562V35.667H28.332L28.333 55.204Z" fill="black"/>
    <path d="M39.228 62H44.046C44.598 62 45.046 61.552 45.046 61C45.046 60.448 44.598 60 44.046 60H39.228C38.676 60 38.228 60.448 38.228 61C38.228 61.552 38.676 62 39.228 62Z" fill="black"/>
    <path d="M48.864 60C48.312 60 47.864 60.448 47.864 61C47.864 61.552 48.312 62 48.864 62H53.682C54.234 62 54.682 61.552 54.682 61C54.682 60.448 54.234 60 53.682 60H48.864Z" fill="black"/>
    <path d="M61 57.5C60.448 57.5 60 57.948 60 58.5V60H58.5C57.948 60 57.5 60.448 57.5 61C57.5 61.552 57.948 62 58.5 62H61C61.552 62 62 61.552 62 61V58.5C62 57.948 61.552 57.5 61 57.5Z" fill="black"/>
    <path d="M61 28.5911C60.448 28.5911 60 29.0391 60 29.5911V34.4091C60 34.9611 60.448 35.4091 61 35.4091C61.552 35.4091 62 34.9611 62 34.4091V29.5911C62 29.0391 61.552 28.5911 61 28.5911Z" fill="black"/>
    <path d="M61 38.2271C60.448 38.2271 60 38.6751 60 39.2271V44.045C60 44.597 60.448 45.045 61 45.045C61.552 45.045 62 44.597 62 44.045V39.2271C62 38.6751 61.552 38.2271 61 38.2271Z" fill="black"/>
    <path d="M61 18.9551C60.448 18.9551 60 19.4031 60 19.9551V24.7731C60 25.3251 60.448 25.7731 61 25.7731C61.552 25.7731 62 25.3251 62 24.7731V19.9551C62 19.4031 61.552 18.9551 61 18.9551Z" fill="black"/>
    <path d="M61 9.31812C60.448 9.31812 60 9.76612 60 10.3181V15.1361C60 15.6881 60.448 16.1361 61 16.1361C61.552 16.1361 62 15.6881 62 15.1361V10.3181C62 9.76612 61.552 9.31812 61 9.31812Z" fill="black"/>
    <path d="M61 47.863C60.448 47.863 60 48.311 60 48.863V53.681C60 54.233 60.448 54.681 61 54.681C61.552 54.681 62 54.233 62 53.681V48.863C62 48.311 61.552 47.863 61 47.863Z" fill="black"/>
    <path d="M61 2H58.5C57.948 2 57.5 2.448 57.5 3C57.5 3.552 57.948 4 58.5 4H60V5.5C60 6.052 60.448 6.5 61 6.5C61.552 6.5 62 6.052 62 5.5V3C62 2.448 61.552 2 61 2Z" fill="black"/>
    <path d="M35.409 3C35.409 2.448 34.961 2 34.409 2H29.591C29.039 2 28.591 2.448 28.591 3C28.591 3.552 29.039 4 29.591 4H34.409C34.961 4 35.409 3.552 35.409 3Z" fill="black"/>
    <path d="M44.045 4C44.597 4 45.045 3.552 45.045 3C45.045 2.448 44.597 2 44.045 2H39.227C38.675 2 38.227 2.448 38.227 3C38.227 3.552 38.675 4 39.227 4H44.045Z" fill="black"/>
    <path d="M19.955 2C19.403 2 18.955 2.448 18.955 3C18.955 3.552 19.403 4 19.955 4H24.773C25.325 4 25.773 3.552 25.773 3C25.773 2.448 25.325 2 24.773 2H19.955Z" fill="black"/>
    <path d="M10.318 2C9.76599 2 9.31799 2.448 9.31799 3C9.31799 3.552 9.76599 4 10.318 4H15.136C15.688 4 16.136 3.552 16.136 3C16.136 2.448 15.688 2 15.136 2H10.318Z" fill="black"/>
    <path d="M53.682 2H48.864C48.312 2 47.864 2.448 47.864 3C47.864 3.552 48.312 4 48.864 4H53.682C54.234 4 54.682 3.552 54.682 3C54.682 2.448 54.234 2 53.682 2Z" fill="black"/>
    <path d="M3 6.5C3.552 6.5 4 6.052 4 5.5V4H5.5C6.052 4 6.5 3.552 6.5 3C6.5 2.448 6.052 2 5.5 2H3C2.448 2 2 2.448 2 3V5.5C2 6.052 2.448 6.5 3 6.5Z" fill="black"/>
    <path d="M3 25.7731C3.552 25.7731 4 25.3251 4 24.7731V19.9551C4 19.4031 3.552 18.9551 3 18.9551C2.448 18.9551 2 19.4031 2 19.9551V24.7731C2 25.3251 2.448 25.7731 3 25.7731Z" fill="black"/>
    <path d="M3 16.1371C3.552 16.1371 4 15.6891 4 15.1371V10.3191C4 9.76709 3.552 9.31909 3 9.31909C2.448 9.31909 2 9.76709 2 10.3191V15.1371C2 15.6891 2.448 16.1371 3 16.1371Z" fill="black"/>
    <path d="M17.327 44.9999C19.333 44.9999 20.966 43.3679 20.966 41.3609C20.966 39.3539 19.334 37.7219 17.327 37.7219C15.32 37.7219 13.688 39.3539 13.688 41.3609C13.688 43.3679 15.32 44.9999 17.327 44.9999ZM17.327 39.7219C18.231 39.7219 18.966 40.4569 18.966 41.3609C18.966 42.2649 18.231 42.9999 17.327 42.9999C16.423 42.9999 15.688 42.2649 15.688 41.3609C15.688 40.4569 16.423 39.7219 17.327 39.7219Z" fill="black"/>
    <path d="M34.611 30.3891C34.867 30.3891 35.123 30.2911 35.318 30.0961L51.076 14.3381V18.3341C51.076 18.8861 51.524 19.3341 52.076 19.3341C52.628 19.3341 53.076 18.8861 53.076 18.3341V11.9241C53.076 11.7941 53.049 11.6641 52.999 11.5421C52.898 11.2981 52.703 11.1031 52.458 11.0011C52.336 10.9501 52.206 10.9241 52.076 10.9241H45.666C45.114 10.9241 44.666 11.3721 44.666 11.9241C44.666 12.4761 45.114 12.9241 45.666 12.9241H49.662L33.904 28.6821C33.513 29.0731 33.513 29.7051 33.904 30.0961C34.099 30.2911 34.355 30.3891 34.611 30.3891Z" fill="black"/>
</svg>
);
export default LowResolutionWarningIcon;