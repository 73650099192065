
import Header from "../../../components/Header";
import StageComplete from "../../../screens/Messages/StageComplete";
import Navigator from "../../../screens/Navigator";

const DocumentUploadCompletedState = ({ onContinue, navigationAction, navigationProps }) => {
    
    return (
         <div className='root-layout-container'>
            <Header />
            <StageComplete
                continue={onContinue}
                message='idpal_document_captured'
            />
            <Navigator
                page={'document_review'}
                action={navigationAction}
                propsToPass={navigationProps}
            />
        </div>
    )
}

export default DocumentUploadCompletedState;