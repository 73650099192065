/**
 * @prettier
 */

// React Packages
import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

// Actions

import { setErrors } from '../../../screens/actions/eVerificationActions.js';
import addressLookupValidatorService from '../../../services/shared/addressLookupValidatorService.js';
import {
    clearEVerificationAddress,
    hideAddressLookup,
    setEVerificationAddress,
    setFullResult,
} from '../../../screens/actions/addressLookupActions.js';

// Services
import ApiService from '../../../services/api/api.js';

// Components
import TextInput from './TextInput';

// Images
import magnifierIcon  from '../../../assets/img/search.svg';

// Config
import { liveFormElements } from '../../../config/accessabilityRules.js';
import { ACTION_LABELS } from '../../../config/dataDogActionLabels.js';


const ACTION = ACTION_LABELS.eVerification.addressLookup;
const errorIds = liveFormElements.addressLookupIds;

const getPlaceholder = countryName => {
    switch (countryName) {
        case 'Ireland':
            return 'idpal_address_lookup_placeholder_ireland';
        case 'United Kingdom':
            return 'idpal_address_lookup_placeholder_uk';
        default:
            return 'idpal_address_lookup_placeholder_default';
    }
} 

const AddressLookupInput = ({
    fullResult,
    clearEVerificationAddress,
    selectedCountry,
    setEVerificationAddress,
    setFullResult,
    setErrors,
    errors,
    hideAddressLookup,
    customFields,
    onChangeCustomField,
    customFieldValue,   
}) => {
    const [timer, setTimer] = useState(null);
    const [showResults, setShowResults] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [results, setResults] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [resultString, setResultString] = useState('');
    const [isAddressLookUpOverlayVisible, setIsAddressLookUpOverlayVisible] = useState(false);

    const resultsRef = useRef();
    const searchFieldRef = useRef();

    const { t } = useTranslation();

    const config = {
        id: 'address_lookup',
        name: 'idpal_address',
        required: 'true',
    };

    const propmtKeepTyping = () => {
        setLoadingMessage('idpal_keep_typing_v2');
    };
    const showValidationMessage = () => {
        setLoadingMessage('idpal_allowed_characters_letters_error');
    };
    const inputUpdated = event => {
            clearEVerificationAddress();
            clearTimeout(timer);
            setLoadingMessage('');
            setShowResults(true);
            setShowSpinner(true);
            setResults([]);
            setNoResults(false);
            setResultString(event.target.value);           

            if (event.target.value.length < 5) {
                setTimer(setTimeout(propmtKeepTyping, 5000));
            } else {
                setTimer(setTimeout(lookupAddress, 1000, event.target.value));                
            }
        }

    const lookupAddress = address => {
        ApiService.lookupAddress(
            address,
            selectedCountry.iso_3166_2
        ).then(response => {
            if (response.code === 400) {
                showValidationMessage();
            } else if (response.data) {
                if (response.data.addresses) {
                    setResults(response.data.addresses);
                    setShowSpinner(false);
                    if (response.data.addresses.length === 0) {
                        setNoResults(true);                       
                    }
                }
            } else {
                setNoResults(true)
            }
        });
    };
    const selectAddress = address => {        
        const errors = addressLookupValidatorService(address);
        setEVerificationAddress(address);
        setErrors(errors);

        // No errors, keep address in input
        if (Object.keys(errors).length === 0) {
            setFullResult(address.address);
            setResultString(address.address);
            setShowResults(false);
            // Missing field, show full form
        } else {
            close();
            setErrors(errors);
        }
        setIsAddressLookUpOverlayVisible(false);
    }

    const close = () => {
        clearTimeout(timer);
        hideAddressLookup();
    }

    const clearFullResult = () => {
       setFullResult('');
       clearEVerificationAddress();
       setShowResults(true);
       handleOpenOverlay();      
    }

    const handleOpenOverlay = () => {
        setIsAddressLookUpOverlayVisible(true);
        setFullResult(null);
    };

    {/*Optional custom field based on selected country*/}
    const showCustomField = () => customFields.map(field => {
            if (selectedCountry.iso_3166_3 ===field.iso) {                                 
                return (
                    <div style={{
                        margin: '10px 0px',
                    }}>
                        <TextInput
                            config={field}
                            error={errors.customField}
                            onChange={onChangeCustomField}
                            actionDataLabel={ACTION.customField}
                            isRequired={field.required}
                            label={field.name}
                            placeholder={field.name}
                            defaultValue={customFieldValue}           
                        />
                    </div>
                    
                )
            }
    });
    
    const showAddressLookupOverlay = () => (
        <div className="address-lookup-overlay-container">
            <button 
                className="back-button" 
                onClick={() => setIsAddressLookUpOverlayVisible(false)}> 
                <span>&#8249;</span> {t('xxx_go_back')}
                                
            </button>
            <TextInput
                id={config.id}
                label={t(getPlaceholder(selectedCountry.name))}
                placeholder={t(getPlaceholder(selectedCountry.name))}
                onChange={inputUpdated}
                isRequired
                error={errors[config.id]}
                config={config}
                inputProps={{
                    ref: searchFieldRef,
                    autoFocus: true,
                }}
            />
       
            <img className="text-input-icon" src={magnifierIcon} alt="" />
            { showSpinner && (
                <>
                    <span className="loading-tooltip-text">{t(loadingMessage)} </span>
                </>
                    
            )}

            {showResults && (
                <>   
                    {results && !noResults && (
                        <ul>
                            {results.map(result => (
                                <li key={result.address}>
                                    <button
                                        className='lookup-result-option'                                        
                                        onClick={() =>
                                            selectAddress(result)
                                        }                                        
                                        data-dd-action-name={
                                            ACTION.resultsButton
                                        } // include if conflict
                                    >
                                        {result.address}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    )}

                    {noResults && (
                        <div className='results'>
                            <div
                                className='result empty'
                                aria-live='polite'
                                id={errorIds.noAddressId}
                            >
                                {t('idpal_no_results')}
                            </div>
                        </div>
                    )}
                </>
            )}
            <button
                className='switch-to-manual-mode'
                onClick={close}
                data-dd-action-name={ACTION.closeButton} // include if conflict
            >
                {t('idpal_enter_address_manually')}
            </button>
        </div>
    );

    const addressLookupControls = () => {
        return fullResult ? (
        <>
            {/* Show address lookup result with button to open overlay again */}
            <button    
                onClick={clearFullResult}
                ref={resultsRef}
                data-dd-action-name={ACTION.searchAddressInput} // include if conflict
                className={`address-result-button`}
            >
                <span className={resultString.length > 0 ? '' : 'place-holder-text'}>
                    {resultString.length > 0 ? resultString : t(getPlaceholder(selectedCountry.name))}
                </span>
                <img src={magnifierIcon} alt="" />
                <label className='address-label required'>
                {t(getPlaceholder(selectedCountry.name))}
                </label>
            </button>        
            <button
                className='switch-to-manual-mode'
                onClick={close}
                data-dd-action-name={ACTION.closeButton} // include if conflict
            >
                {t('idpal_enter_address_manually')}
            </button>
            {showCustomField()}
        </>
        ):(            
        <>  
            {/* Show address lookup button to open overlay */}
            <button 
                onClick={handleOpenOverlay} 
                className={`open-overlay address-result-button ${errors?.address1? 'error': ''}`}
            >
                <span className='place-holder-text'>
                   {t(getPlaceholder(selectedCountry.name))}
                </span>                
                <img src={magnifierIcon}/>
                <label className='address-label required'>
                    {t(getPlaceholder(selectedCountry.name))}
                </label>
            </button>
            {errors?.address1 && (<span className="error-text">{errors?.address1}</span>)}
            <button
                className='switch-to-manual-mode'
                onClick={close}
                data-dd-action-name={ACTION.closeButton} // include if conflict
            >
                {t('idpal_enter_address_manually')}
            </button>
            {showCustomField()}
        </>
        )
    };

    return (
        <div className='address-lookup-wrapper'>
            { isAddressLookUpOverlayVisible ?
             showAddressLookupOverlay() : 
                addressLookupControls()
            }
        </div>
    );
    }

function mapStateToProps(state) {
    return {
        selectedCountry: state.eVerification.selectedCountry,
        fullResult: state.addressLookup.fullResult,
        branding: state.config.profile,
        errors: state.eVerification.errors,
        customFields: state.eVerification.customFields,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideAddressLookup,
            setEVerificationAddress,
            setFullResult,
            setErrors,
            clearEVerificationAddress,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressLookupInput)
;
