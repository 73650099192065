import StageComplete from "../../../screens/Messages/StageComplete";
import Header from "../../../screens/Header";
import Navigator from "../../../screens/Navigator";

const DocumentUploadCompletedState = ({ onContinue, navigationAction, navigationProps }) => (
        <>
         <Header />
            <StageComplete
                continue={onContinue}
                message='idpal_document_captured'
            />
            <Navigator
                page={'document_review'}
                action={navigationAction}
                propsToPass={navigationProps}
            />
        </>);

export default DocumentUploadCompletedState;