/**
 * @prettier
 */

const SelectInput = ({
    error, 
    isRequired, 
    label,
    config, 
    onChange,
    onBlur = () => {},
    actionDataLabel,
    defaultValue,
    options,
}) =>  {
    return (
            <div className={ `custom-select-wrapper ${error ? 'error' : ''}`}>
                <select
                    className="custom-select"
                    id={config.id}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={defaultValue}
                    data-dd-action-name={actionDataLabel}
                >
                    {options.map(option => (
                        <option
                            key={option[config.optionValue]}
                            value={option[config.optionValue]}
                        >
                            {option[config.optionName]}
                        </option>
                    ))}
                </select>
                <label
                    id={config.id}
                    className={`custom-label ${isRequired? 'required': ''}`}
                    style={{ position: 'absolute' }}
               > 
                 {label}
               </label>
                {error && (<span className="error-text">{error}</span>)}
            </div>
        );
}

export default SelectInput;
