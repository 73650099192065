/**
 * @prettier
 */

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// Images
import loadingGif from '../../assets/gifs/loading.gif';
import { imageAlt } from '../../config/accessabilityRules';

// Services
import apiService from '../../services/api/api';
import logService from '../../services/shared/logService';
import DataDogService from '../../services/shared/datadogService';
import { regenerateSessionNewUI } from '../../services/shared/authenticationService';

// Components
import Header from '../../components/Header';
import SubmissionFlowStepper from '../../components/SubmissionFlowStepper';
import CustomButton from '../../components/CustomButton';

import Navigator from '../../screens/Navigator';

import { ACTION_LABELS } from '../../config/dataDogActionLabels';
// Constants
const ACTION = ACTION_LABELS.consent;

const ConsentPage = ({ policies }) => {
    const [isConsentChecked, setIsConsentChecked] = useState(false);
    const [refreshAttempted, setRefreshAttempted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [navigation, setNavigation] = useState({
        action: 'load',
        props: null,
    });

    const { t } = useTranslation();

    const handleContinue = async () => {
        setIsLoading(true);
        try {
            const res = await apiService.postConsent(isConsentChecked);
            if (res) {
                DataDogService.log('Consent successfully sent');
                setNavigation({ action: 'next' });
            }
        } catch (error) {
            if (error.status === 403 && !refreshAttempted) {
                regenerateSessionNewUI(
                    handleContinue,
                    [],
                    setRefreshAttempted,
                    setNavigation
                );
            } else {
                DataDogService.createError('Unable to send consent');
                logService.error(error);
                setNavigation({
                    action: 'error',
                    props: {
                        retryAction: () => handleContinue(),
                        error: error,
                    },
                });
            }
        } finally {
            setIsLoading(false);
        }
    };
    if (isLoading) {
        return (
            <div className='root-layout-container consent-page'>
                <Header />
                <SubmissionFlowStepper />
                <div className='u-display-loading u-text-center'>
                    <img
                        alt={imageAlt.loading}
                        src={loadingGif}
                        className='capture'
                    />
                </div>
            </div>
        );
    }
    return (
        <div className='root-layout-container consent-page'>
            <Header />
            <SubmissionFlowStepper />
            <h1 className='page-title'>{t('idpal_before_you_continue')}</h1>
            <p className='consent-message'>
                {t('idpal_consent_message_1')}
                <a
                    href={policies?.privacy_policy || ''}
                    target={'_blank'}
                    rel='noreferrer'
                    data-dd-action-name={ACTION.privacyLink}
                    aria-label={t('idpal_privacy_policy_link')}
                >
                    {t('idpal_privacy_policy_link')}
                </a>
                <br />
                <br />
                {t('idpal_consent_message_2')}
            </p>
            <div className='consent-checkbox-container'>
                <div className='checkbox-mark-container'>
                    <input
                        id='check'
                        type='checkbox'
                        onChange={() => setIsConsentChecked(!isConsentChecked)}
                    />
                    <label class='checkmark-element' htmlFor='check'>
                        <svg viewBox='0 0 50 50'>
                            <path d='M5 30 L 20 45 L 60 5'></path>
                        </svg>
                    </label>
                </div>
                <label className='checkbox-label' htmlFor='check'>
                    {t('idpal_i_consent')}
                </label>
            </div>
            <div className='cta-container'>
                <CustomButton
                    isDisabled={!isConsentChecked}
                    id='continue'
                    label={t('idpal_continue')}
                    handleClick={handleContinue}
                />
            </div>
            <Navigator
                page={'consent'}
                action={navigation.action}
                propsToPass={navigation.props}
            />
        </div>
    );
};
function mapStateToProps(state) {
    return {
        submission: state.submissionState.details,
        branding: state.config.profile.data.company_branding,
        policies: state.config.profile.data.policies,
    };
}
export default connect(mapStateToProps)(ConsentPage);
