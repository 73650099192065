/**
 * @prettier
 */
// React Packages
import { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation, Trans } from 'react-i18next';
// Actions
import {
    setCardType,
    setUserSelectedCardType,
} from '../../screens/actions/idPropertiesActions';
// } from '.././actions/idPropertiesActions';
import { setInstanceID } from '../../screens/actions/configActions';

// Services
import { isIdPalCameraEnabled } from '../../services/shared/helpers';

// Components
import Header from '../../components/Header';
import Navigator from '../../screens/Navigator';
import SelectDocBackgroundIcon from './components/SelectDocBackgroundIcon';
import PassportIcon from "./components/DynamicColoredIcons/PassportIcon";
import DrivingLicenseIcon from "./components/DynamicColoredIcons/DrivingLicenseIcon";
import IDCardIcon from "./components/DynamicColoredIcons/IDCardIcon";
import ChevronIcon from "./components/DynamicColoredIcons/ChevronIcon";
import { allowedDocumentsTypes } from '../../config/documents';

const DOCUMENT_TYPES = Object.keys(allowedDocumentsTypes);

const renderButtonContent = (docType, fillColor, title) => {
    let icon;
    switch(docType) {
        case 'passport':
            icon = <PassportIcon fill={fillColor}/>;
            break;
        case 'driving_license':
            icon = <DrivingLicenseIcon fill={fillColor}/>;
            break;
        case 'national_id':
            icon = <IDCardIcon fill={fillColor}/>;
            break;
        default:
            icon = <PassportIcon fill={fillColor}/>;
    }
    return (<>
            <div className="button-icon-wrapper">{icon}</div>
            <span>{title}</span>
            <div style={{display:'flex', marginLeft:'auto'}}>
                <ChevronIcon fill={fillColor}/>
            </div>
        </>)

}

const SelectDocumentPage = ({
    setUserSelectedCardType,
    captureMethod,
    bypassAcuant,
    setCardType,
    isDocSelectionEnabled,
    instanceID,
    acceptedDocuments,
    branding,
    }) => {
    const primaryFocusRef = useRef();
    const [navigation, setNavigation] = useState({
        action: 'load',
        props: {
            instanceID: instanceID,
        },
    });
    const { t } = useTranslation();

    const navigateCamera = documentId => {
        setNavigation({
                action: 'next',
                props: {
                    documentId: documentId,
                    instanceID: instanceID,
                    isIdPalCamera:
                        isIdPalCameraEnabled(captureMethod) ||
                        bypassAcuant,
                },
        });
    }
    const openCamera = type => {
        setUserSelectedCardType(type);
        setCardType(type);
        navigateCamera(type);
    }
    const getBrandingColor = () => `#${branding.data.company_branding.primary_color}`;

    const isDocumentTypeEnabled = documentType => {
        if (isDocSelectionEnabled){
            return acceptedDocuments.includes(documentType)
        } else {
            const defaultAcceptedDocuments = DOCUMENT_TYPES.filter(docType => (docType  === 'passport' || docType === 'driving_license' ));
            return defaultAcceptedDocuments.includes(documentType)
        }
    }

    useEffect(() => {
        if(primaryFocusRef && primaryFocusRef.current) {
            primaryFocusRef.current.focus();
        }
        document.title = t('idpal_doc_title_select_doc');
        return () => null
    }, []);

    return (
        <div className="root-layout-container">
            <Header showLanguageSelect/>
            <div className="select-document-title-wrapper">
                <SelectDocBackgroundIcon fill={getBrandingColor()}/>
                <h1>
                    <Trans
                        i18nKey="idpal_select_document_type"
                        components={{
                            br: <br />,
                        }}
                    />
                </h1>
            </div>
            <div className="select-document-button-container">
                {isDocumentTypeEnabled('passport') && (
                    <button
                        aria-label={t("idpal_passport")}
                        onClick={()=> openCamera(1)}
                        className='outlined-button'>
                        {renderButtonContent('passport', getBrandingColor(), t("idpal_passport"))}
                    </button>)}
                {isDocumentTypeEnabled('driving_license') && (
                    <button
                        aria-label={t("idpal_driving_licence")}
                        onClick={()=> openCamera(31)}
                        className='outlined-button'>
                        {renderButtonContent('driving_license', getBrandingColor(), t("idpal_driving_licence"))}
                    </button>)}

                {isDocumentTypeEnabled('national_id') &&
                    (<button
                        aria-label={t("xxx_id_card")}
                        onClick={()=> openCamera(4)}
                        className='outlined-button'>
                        {renderButtonContent('national_id', getBrandingColor(), t("xxx_id_card"))}
                    </button>)}
             </div>
            <Navigator
                page={'document_selection'}
                action={navigation.action}
                propsToPass={navigation.props}
            />
        </div>
    );
    }

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        acuantInitialized: state.config.acuantInitialized,
        bypassAcuant: state.config.bypassAcuant,
        cardType: state.idProperties.cardType,
        branding: state.config.profile,
        acceptedDocuments: state.config.profile.data.accepted_documents,
        uuidValid: state.config.uuidValid,
        limitReached: state.config.limitReached,
        pendingItems: state.spinner.pendingItems,
        captureMethod:
            state.submissionState.screens.document_upload.capture_method,
        isDocSelectionEnabled: state.submissionState.isDocSelectionEnabled,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { setCardType, setUserSelectedCardType, setInstanceID },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDocumentPage);
