const AddressDataIcon = ({ fill }) =>  (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="26" cy="34" r="26" fill={fill} fillOpacity="0.24"/>
        <g clipPath="url(#clip0_0_1)">
            <path d="M59.3727 19.6655L54.2502 15.0086V1.91661C54.2502 0.858088 53.3921 0 52.3336 0H44.667C43.6085 0 42.7504 0.858088 42.7504 1.91661V4.55413L38.4892 0.680379L38.2896 0.498986C38.1525 0.374374 38.0019 0.273122 37.8428 0.195228C37.8357 0.191814 37.8284 0.189299 37.8213 0.185885C37.3027 -0.0601048 36.698 -0.0601048 36.1793 0.185885C36.1722 0.189209 36.1648 0.191724 36.1577 0.195228C35.9986 0.273122 35.848 0.374374 35.7109 0.498986L35.5143 0.677773L14.628 19.6655C13.8448 20.3775 13.787 21.5897 14.4991 22.373C15.2111 23.1562 16.4233 23.214 17.2066 22.5019L17.834 21.9316V44.0835C17.834 45.142 18.692 46.0001 19.7506 46.0001H29.3338H44.667H54.2503C55.3088 46.0001 56.1669 45.142 56.1669 44.0835V21.9315L56.7942 22.5018C57.5775 23.2139 58.7896 23.1561 59.5017 22.3729C60.2137 21.5896 60.156 20.3775 59.3727 19.6655ZM46.5836 3.83332H50.4169V11.5239L46.5836 8.03894V3.83332ZM33.1671 42.1668V28.7502C33.1671 27.6909 34.0244 26.8336 35.0838 26.8336H38.9171C39.9764 26.8336 40.8337 27.6909 40.8337 28.7502V42.1668H33.1671ZM52.3336 42.1668H44.667V28.7502C44.667 25.5738 42.0935 23.0003 38.9171 23.0003H35.0838C31.9074 23.0003 29.3338 25.5738 29.3338 28.7502V42.1668H21.6673V18.447L37.0004 4.5075L43.3677 10.2962C43.3711 10.2993 43.3743 10.3026 43.3777 10.3058L47.7865 14.3135L52.3335 18.4471V42.1668H52.3336Z" fill="black"/>
        </g>
        <defs>
            <clipPath id="clip0_0_1">
                <rect width="46" height="46" fill="white" transform="translate(14)"/>
            </clipPath>
        </defs>
    </svg>
);
export default AddressDataIcon;