import Header from "../../../components/Header";
import StageComplete from "../../../screens/Messages/StageComplete";
import Navigator from "../../../screens/Navigator";

const CaptureCompleteState = ({ navigateNext, navigation }) => {
    return (
        <>
            <Header />
            <StageComplete
                continue={navigateNext}
                message='idpal_document_captured'
            />
            <Navigator
                page={'document_review'}
                action={navigation.action}
                propsToPass={navigation.props}
            />
        </>
    )
}
export default CaptureCompleteState;