const PersonalDataIcon = ({fill}) => {
    return (
        <svg style={{minWidth: '60px', height: '60px'}} viewBox="0 0 60 60">
            <path d="M37.4375 20.1562C40.6768 20.1562 43.3125 17.7449 43.3125 14.7812C43.3125 11.8176 40.6768 9.40625 37.4375 9.40625C34.1982 9.40625 31.5625 11.8176 31.5625 14.7812C31.5625 17.7449 34.1982 20.1562 37.4375 20.1562ZM37.4375 12.0938C39.0575 12.0938 40.375 13.2991 40.375 14.7812C40.375 16.2634 39.0575 17.4688 37.4375 17.4688C35.8175 17.4688 34.5 16.2634 34.5 14.7812C34.5 13.2991 35.8175 12.0938 37.4375 12.0938Z" fill="black"/>
            <path d="M37.4375 21.5C31.7681 21.5 27.1562 25.7194 27.1562 30.9062V32.25C27.1562 32.9917 27.8142 33.5938 28.625 33.5938H46.25C47.0607 33.5938 47.7188 32.9917 47.7188 32.25V30.9062C47.7188 25.7194 43.1069 21.5 37.4375 21.5ZM30.0938 30.9062C30.0938 27.2015 33.3882 24.1875 37.4375 24.1875C41.4868 24.1875 44.7812 27.2015 44.7812 30.9062H30.0938Z" fill="black"/>
            <path d="M50.6562 0H21.2812C18.8512 0 16.875 1.80802 16.875 4.03125V9.40625H15.4062C14.5955 9.40625 13.9375 10.0083 13.9375 10.75C13.9375 11.4917 14.5955 12.0938 15.4062 12.0938H16.875V14.7812H15.4062C14.5955 14.7812 13.9375 15.3833 13.9375 16.125C13.9375 16.8668 14.5955 17.4688 15.4062 17.4688H16.875V20.1562H15.4062C14.5955 20.1562 13.9375 20.7582 13.9375 21.5C13.9375 22.2418 14.5955 22.8438 15.4062 22.8438H16.875V25.5312H15.4062C14.5955 25.5312 13.9375 26.1332 13.9375 26.875C13.9375 27.6168 14.5955 28.2188 15.4062 28.2188H16.875V30.9062H15.4062C14.5955 30.9062 13.9375 31.5082 13.9375 32.25C13.9375 32.9917 14.5955 33.5938 15.4062 33.5938H16.875V38.9688C16.875 41.192 18.8512 43 21.2812 43H50.6562C53.0863 43 55.0625 41.192 55.0625 38.9688V4.03125C55.0625 1.80802 53.0863 0 50.6562 0ZM52.125 38.9688C52.125 39.7098 51.4663 40.3125 50.6562 40.3125H21.2812C20.4712 40.3125 19.8125 39.7098 19.8125 38.9688V33.5938H21.2812C22.092 33.5938 22.75 32.9917 22.75 32.25C22.75 31.5082 22.092 30.9062 21.2812 30.9062H19.8125V28.2188H21.2812C22.092 28.2188 22.75 27.6168 22.75 26.875C22.75 26.1332 22.092 25.5312 21.2812 25.5312H19.8125V22.8438H21.2812C22.092 22.8438 22.75 22.2418 22.75 21.5C22.75 20.7582 22.092 20.1562 21.2812 20.1562H19.8125V17.4688H21.2812C22.092 17.4688 22.75 16.8668 22.75 16.125C22.75 15.3833 22.092 14.7812 21.2812 14.7812H19.8125V12.0938H21.2812C22.092 12.0938 22.75 11.4917 22.75 10.75C22.75 10.0083 22.092 9.40625 21.2812 9.40625H19.8125V4.03125C19.8125 3.29017 20.4712 2.6875 21.2812 2.6875H50.6562C51.4663 2.6875 52.125 3.29017 52.125 4.03125V38.9688Z" fill="black"/>
            <circle cx="26" cy="31" r="26" fill={fill} fillOpacity="0.24"/>
        </svg>

    )
}
export default PersonalDataIcon;