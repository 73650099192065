import { useTranslation } from "react-i18next";
import { imageAlt } from "../../../config/accessabilityRules";
import CustomButton from "../../../components/CustomButton";

const PreviewState = ({ 
    primaryFocusRef,
    poaImage,
    handleSubmitCapturedPdf,
    handleRetryCapture,
    }) => {
    const { t } = useTranslation();
    return (
        <>
        {/* Show preview state */}
      
            <h1
                className='page-title'
                ref={primaryFocusRef}
                tabIndex={0}
            >
                {t('idpal_ensure_details_are_clearly_readable')}
            </h1>
            <div className='image-wrapper'>
                <img
                    className='preview-image'
                    src={poaImage}
                    alt={imageAlt.proofOfAddress}
                />
            </div>
        <div className='cta-container preview-state'>                      
            <CustomButton
                handleClick={handleRetryCapture}
                label={t('idpal_retry_btn')}
                outlined
            />
            <CustomButton
                label={t('idpal_accept_btn')}
                handleClick={
                    handleSubmitCapturedPdf
                }
            />  
        </div>
    </>
    )
}
export default PreviewState;