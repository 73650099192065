const DriversLicenseFrontIcon = ({fill}) => (
    <svg viewBox="0 0 322 210" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path  clipRule="evenodd" d="M20.9226 4.16001H22.0026V2.00001H20.9226H3.08H2V4.16001H2.00005V20.6388V21.7188H4.16005V20.6388V4.16001H20.9226Z" fill="#C5C5C5"/>
        <path d="M22.0026 4.16001H23.4426V5.60001H22.0026V4.16001ZM22.0026 2.00001V0.560007H23.4426V2.00001H22.0026ZM2 2.00001H0.560003V0.560007H2V2.00001ZM2 4.16001V5.60001H0.560003V4.16001H2ZM2.00005 4.16001V2.72001H3.44005V4.16001H2.00005ZM2.00005 21.7188V23.1588H0.560049V21.7188H2.00005ZM4.16005 21.7188H5.60005V23.1588H4.16005V21.7188ZM4.16005 4.16001H2.72005V2.72001H4.16005V4.16001ZM22.0026 5.60001H20.9226V2.72001H22.0026V5.60001ZM23.4426 2.00001V4.16001H20.5626V2.00001H23.4426ZM20.9226 0.560007H22.0026V3.44001H20.9226V0.560007ZM3.08 0.560007H20.9226V3.44001H3.08V0.560007ZM2 0.560007H3.08V3.44001H2V0.560007ZM0.560003 4.16001V2.00001H3.44V4.16001H0.560003ZM2.00005 5.60001H2V2.72001H2.00005V5.60001ZM0.560049 20.6388V4.16001H3.44005V20.6388H0.560049ZM0.560049 21.7188V20.6388H3.44005V21.7188H0.560049ZM4.16005 23.1588H2.00005V20.2787H4.16005V23.1588ZM5.60005 20.6388V21.7188H2.72005V20.6388H5.60005ZM5.60005 4.16001V20.6388H2.72005V4.16001H5.60005ZM20.9226 5.60001H4.16005V2.72001H20.9226V5.60001Z" fill="#C5C5C5"/>
        <path  clipRule="evenodd" d="M4.16001 189.08V188H2.00001V189.08L2.00001 206.944V208.024H4.16001V208.024H20.6391H21.7191V205.864H20.6391H4.16001L4.16001 189.08Z" fill="black"/>
        <path d="M4.16001 188V186.56H5.60001V188H4.16001ZM2.00001 188H0.560011V186.56H2.00001V188ZM2.00001 208.024L2.00001 209.464H0.560011L0.560011 208.024H2.00001ZM4.16001 208.024H5.60001L5.60001 209.464H4.16001L4.16001 208.024ZM4.16001 208.024H2.72001V206.584H4.16001V208.024ZM21.7191 208.024H23.1591L23.1591 209.464H21.7191L21.7191 208.024ZM21.7191 205.864V204.424H23.1591V205.864H21.7191ZM4.16001 205.864V207.304H2.72001V205.864H4.16001ZM5.60001 188V189.08H2.72001V188H5.60001ZM2.00001 186.56H4.16001V189.44H2.00001V186.56ZM0.560011 189.08V188H3.44001V189.08H0.560011ZM0.560011 206.944L0.560011 189.08H3.44001L3.44001 206.944H0.560011ZM0.560011 208.024V206.944H3.44001V208.024H0.560011ZM4.16001 209.464H2.00001L2.00001 206.584H4.16001L4.16001 209.464ZM5.60001 208.024V208.024H2.72001V208.024H5.60001ZM20.6391 209.464H4.16001L4.16001 206.584H20.6391L20.6391 209.464ZM21.7191 209.464H20.6391L20.6391 206.584H21.7191L21.7191 209.464ZM23.1591 205.864V208.024H20.2791V205.864H23.1591ZM20.6391 204.424H21.7191V207.304H20.6391V204.424ZM4.16001 204.424H20.6391V207.304H4.16001V204.424ZM5.60001 189.08L5.60001 205.864H2.72001L2.72001 189.08H5.60001Z" fill="#C5C5C5"/>
        <path clipRule="evenodd" d="M301.08 205.559H300V207.719H301.08H318.944H320.024V205.559H320.024V189.08V188H317.864V189.08V205.559H301.08Z" fill="#C5C5C5"/>
        <path d="M300 205.559H298.56V204.119H300V205.559ZM300 207.719V209.159H298.56V207.719H300ZM320.024 207.719H321.464V209.159H320.024V207.719ZM320.024 205.559V204.119H321.464V205.559H320.024ZM320.024 205.559V206.999H318.584V205.559H320.024ZM320.024 188V186.56H321.464V188H320.024ZM317.864 188H316.424V186.56H317.864V188ZM317.864 205.559H319.304V206.999H317.864V205.559ZM300 204.119H301.08V206.999H300V204.119ZM298.56 207.719V205.559H301.44V207.719H298.56ZM301.08 209.159H300V206.279H301.08V209.159ZM318.944 209.159H301.08V206.279H318.944V209.159ZM320.024 209.159H318.944V206.279H320.024V209.159ZM321.464 205.559V207.719H318.584V205.559H321.464ZM320.024 204.119H320.024V206.999H320.024V204.119ZM321.464 189.08V205.559H318.584V189.08H321.464ZM321.464 188V189.08H318.584V188H321.464ZM317.864 186.56H320.024V189.44H317.864V186.56ZM316.424 189.08V188H319.304V189.08H316.424ZM316.424 205.559V189.08H319.304V205.559H316.424ZM301.08 204.119H317.864V206.999H301.08V204.119Z" fill="#C5C5C5"/>
        <path clipRule="evenodd" d="M317.559 20.9224V22.0024H319.719V20.9224V3.07982V1.99982H317.559V1.99987H301.08H300V4.15987H301.08H317.559V20.9224Z" fill="#C5C5C5"/>
        <path d="M317.559 22.0024V23.4424H316.119V22.0024H317.559ZM319.719 22.0024H321.159V23.4424H319.719V22.0024ZM319.719 1.99982V0.55982H321.159V1.99982H319.719ZM317.559 1.99982H316.119V0.55982H317.559V1.99982ZM317.559 1.99987H318.999V3.43987H317.559V1.99987ZM300 1.99987H298.56V0.559868H300V1.99987ZM300 4.15987V5.59987H298.56V4.15987H300ZM317.559 4.15987V2.71987H318.999V4.15987H317.559ZM316.119 22.0024V20.9224H318.999V22.0024H316.119ZM319.719 23.4424H317.559V20.5624H319.719V23.4424ZM321.159 20.9224V22.0024H318.279V20.9224H321.159ZM321.159 3.07982V20.9224H318.279V3.07982H321.159ZM321.159 1.99982V3.07982H318.279V1.99982H321.159ZM317.559 0.55982H319.719V3.43982H317.559V0.55982ZM316.119 1.99987V1.99982H318.999V1.99987H316.119ZM301.08 0.559868H317.559V3.43987H301.08V0.559868ZM300 0.559868H301.08V3.43987H300V0.559868ZM298.56 4.15987V1.99987H301.44V4.15987H298.56ZM301.08 5.59987H300V2.71987H301.08V5.59987ZM317.559 5.59987H301.08V2.71987H317.559V5.59987ZM316.119 20.9224V4.15987H318.999V20.9224H316.119Z" fill="#C5C5C5"/>
        <g clipPath="url(#clip0_0_1)">
            <path d="M154.881 89.6412C154.881 96.2274 154.936 102.814 154.862 109.4C154.751 118.927 147.419 126.292 137.918 126.348C124.755 126.422 111.592 126.44 98.4299 126.348C88.558 126.273 81.4113 119.075 81.3649 109.214C81.3 96.0418 81.2815 82.8695 81.3649 69.6971C81.4298 60.0405 88.5951 52.8884 98.3001 52.8235C111.574 52.7307 124.857 52.7307 138.131 52.8235C147.604 52.8977 154.64 60.0405 154.871 69.5487C154.871 69.7806 154.871 70.0125 154.871 70.2444C154.871 76.7193 154.871 83.1849 154.871 89.6597L154.881 89.6412ZM144.035 117.192C144.517 117.247 144.999 117.312 145.481 117.368C146.343 114.873 147.91 112.396 147.956 109.882C148.207 96.3758 148.17 82.8509 148.049 69.3353C147.993 63.6582 144.184 59.6137 138.474 59.5303C124.857 59.3262 111.231 59.3355 97.6142 59.5581C92.1082 59.6508 88.215 63.6953 88.1501 69.1405C88.0018 82.7674 88.0482 96.4036 88.1501 110.031C88.1687 112.943 89.2903 115.541 92.1638 117.303C94.7314 110.049 99.4959 104.901 106.012 101.283C94.7777 90.5688 98.0498 78.0829 104.409 71.8028C111.824 64.4931 123.689 64.2612 131.355 71.3576C139.938 79.3074 139.623 90.7172 130.335 101.311C136.777 104.947 141.523 110.105 144.035 117.183V117.192ZM118.637 72.916C111.398 72.6933 105.465 78.2684 105.308 85.4483C105.15 92.4426 110.777 98.3424 117.775 98.5372C124.801 98.732 130.623 93.2126 130.873 86.1347C131.123 79.0476 125.645 73.1201 118.637 72.916ZM137.38 119.427C134.553 110.392 125.969 104.78 116.709 105.309C108.524 105.782 99.9593 112.563 99.088 119.427H137.38Z" fill="black"/>
            <path d="M244.015 36.7104H80.9848C71.9953 36.7104 64.7078 44.0034 64.7078 52.9997V154C64.7078 162.996 71.9953 170.289 80.9848 170.289H244.015C253.005 170.289 260.292 162.996 260.292 154V52.9997C260.292 44.0034 253.005 36.7104 244.015 36.7104Z" stroke="black" strokeWidth="8" strokeMiterlimit="10"/>
            <path d="M238.509 106.283H171.769C171.002 106.283 170.379 106.906 170.379 107.674C170.379 108.443 171.002 109.066 171.769 109.066H238.509C239.277 109.066 239.9 108.443 239.9 107.674C239.9 106.906 239.277 106.283 238.509 106.283Z" stroke="black" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M238.676 119.743H171.936C171.168 119.743 170.546 120.366 170.546 121.134C170.546 121.903 171.168 122.526 171.936 122.526H238.676C239.444 122.526 240.066 121.903 240.066 121.134C240.066 120.366 239.444 119.743 238.676 119.743Z" stroke="black" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M238.676 135.513H85.5639C84.796 135.513 84.1735 136.136 84.1735 136.904C84.1735 137.673 84.796 138.296 85.5639 138.296H238.676C239.444 138.296 240.066 137.673 240.066 136.904C240.066 136.136 239.444 135.513 238.676 135.513Z" stroke="black" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M238.676 148.5H85.5639C84.796 148.5 84.1735 149.122 84.1735 149.891C84.1735 150.659 84.796 151.282 85.5639 151.282H238.676C239.444 151.282 240.066 150.659 240.066 149.891C240.066 149.122 239.444 148.5 238.676 148.5Z" stroke="black" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M238.778 57.3501H172.038C171.27 57.3501 170.648 57.9731 170.648 58.7415C170.648 59.51 171.27 60.133 172.038 60.133H238.778C239.546 60.133 240.168 59.51 240.168 58.7415C240.168 57.9731 239.546 57.3501 238.778 57.3501Z" stroke="black" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M238.936 70.8193H172.196C171.428 70.8193 170.805 71.4423 170.805 72.2108C170.805 72.9793 171.428 73.6022 172.196 73.6022H238.936C239.703 73.6022 240.326 72.9793 240.326 72.2108C240.326 71.4423 239.703 70.8193 238.936 70.8193Z" stroke="black" strokeWidth="4" strokeMiterlimit="10"/>
        </g>
        <path d="M79.1402 193.176C56.4968 193.066 38.2301 174.62 38.3403 151.977L38.6126 96.0629C38.7229 73.4195 57.1684 55.1528 79.8118 55.263L204.888 55.8721C227.531 55.9824 245.798 74.4279 245.688 97.0713L245.416 152.985C245.305 175.629 226.86 193.896 204.217 193.785L79.1402 193.176Z" fill={fill} fillOpacity="0.25"/>
        <defs>
            <clipPath id="clip0_0_1">
            <rect width="203" height="141" fill="white" transform="translate(61 33)"/>
            </clipPath>
        </defs>
    </svg>
) 
export default DriversLicenseFrontIcon;