/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { environment } from '../../config/environment';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

// Other Packages
import i18next from 'i18next';
import axios from 'axios';

// Actions
import {
    decrementSidesLeft,
    incrementSidesLeft,
    resetIDProperties,
    setCardOrientation,
    setCardType,
    isExpiredTwoPagePassport,
} from '../../screens/actions/idPropertiesActions';
import {
    setRetryLimit,
    completeStage,
    submissionAttempt,
} from '../../screens/actions/submissionStateActions';
import { clearError } from '../../screens/actions/navigatorActions';

// Services
import imageValidatorService from '../../services/shared/imageValidatorService';
import documentService from '../../services/shared/documentService';
import apiService from '../../services/api/api';
import logService from '../../services/shared/logService';
import selectStorage from '../../services/shared/selectStorage';
import {
    areCookiesEnabled,
    isIdPalCameraEnabled,
    isSubmissionComplete,
    getKeyByValue,
} from '../../services/shared/helpers';

// Components
import Processing from '../../screens/Processing';
import Navigator from '../../screens/Navigator';

// Config
import DataDogService from '../../services/shared/datadogService';
import { allowedDocumentsTypes, titleMap } from '../../config/documents';

//Images
import documentError from '../../assets/gifs/idpal_idcard_error.gif';
import documentGlare from '../../assets/gifs/idpal_idcard_glare_error.gif';
import documentNotFound from '../../assets/gifs/idpal_passport.gif';


import Header from '../../components/Header';
import DocumentReviewState from './components/DocumentReviewState';
import FailedVerification from './components/FailedVerificationState';
import OutOfRetryAttemptsState from './components/OutOfRetryAttemptsState';
import SubmissionCompleteState from './components/SubmissionCompleteState';
import DocumentUploadCompletedState from './components/DocumentUploadCompleteState';
import SubmissionFlowStepper from '../../components/SubmissionFlowStepper';
import ModalPopup from './components/ModalPopup';
class ProcessedImageResult extends Component {
    constructor(props) {
        super(props);
        this.pageFocusRef = React.createRef();
        this.state = {
            currentDocumentRegion: 0,
            document: {},
            processing: false,
            validation: {
                messages: '',
                validated: false,
            },
            classification: {
                messages: '',
                classified: false,
                detail: '',
            },
            verificationStatus: '',
            callType: 'classify',
            failed: false,
            expired: false,
            isAcceptedDoc: true,
            navigation: {
                action: 'load',
                props: null,
            },
        };
        this.isImageOrientationPortrait = this.isImageOrientationPortrait.bind(this);
        this.isTwoPagePassport = this.isTwoPagePassport.bind(this);
        this.getImageData = this.getImageData.bind(this);
        this.retryDocumentUpload = this.retryDocumentUpload.bind(this);
        this.proceedToNextStep = this.proceedToNextStep.bind(this);
    }

    // Cancels the classification request
    CancelToken = axios.CancelToken;
    source = this.CancelToken.source();

    /**
     * React component life cycle hook.
     */
    componentDidMount() {
        // Set the callType to display the correct loading messages
        if (this.props.sidesLeft === 2) {
            this.setState({ callType: 'classify' });
        } else {
            this.setState({ callType: 'verify' });
        }

        // Set document title based on submission state
        const { t } = this.props;
        if (this.props.completed === 1) {
            document.title = t('idpal_doc_title_message_complete');
        }

        if (this.props.completed === 1) {
            return;
        }

        // Sets focus to primary heading on first render
        if (this.pageFocusRef && this.pageFocusRef.current) {
            this.pageFocusRef.current.focus();
        }

        // Record a submission attempt on this side
        this.props.submissionAttempt(
            this.props.sidesLeft === 2 ? 'front' : 'back'
        );

        // Define document being processed
        const documentId = documentService.findById(
            this.props.location.state.properties.documentId
        );

        // Set state properties
        this.setState({
            processing: false,
            document: documentId,
        });

        // TODO pass correctly formatted data
        // Define image properties required for validation
        const properties = {
            ...this.props.location.state.properties,
            originalImage: this.getImageData(),
            croppedImage: this.props.croppedImage,
            croppedImageWidth: this.props.location.state.width,
            croppedImageHeight: this.props.location.state.height,
            isPassport: this.props.location.state.properties.isPassport,
            document: documentId,
        };

        // Log outcome
        logService.log('Document analysis result from SDK', properties);

        // Back of ID does not need to be classified
        if (this.props.orientation === 1) {
            this.setState({
                classification: {
                    ...this.state.classification,
                    classified: true,
                },
            });
            this.validateUploadedDocument(properties);
        } else {
            this.classifyUploadedDocument(properties, this.source.token);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Sets focus to primary heading on first render
        if (this.pageFocusRef && this.pageFocusRef.current) {
            this.pageFocusRef.current.focus();
        }

        // Set document title based on submission state
        this.setDocumentTitle();

        // Logs verification and classification messages to datadog
        if (this.state.validation.messages !== prevState.validation.messages) {
            if (this.state.validation.messages[0]?.length) {
                DataDogService.log(
                    `Validation Message: ${this.state.validation.messages[0]}`
                );
            }
        }

        if (
            this.state.classification.messages !==
            prevState.classification.messages
        ) {
            DataDogService.log(
                `Classification Message: ${this.state.classification.messages}`
            );
        }

        if (
            this.state.classification.detail !== prevState.classification.detail
        ) {
            DataDogService.log(
                `Classification Call Status: ${this.state.classification.detail}`
            );
        }

        if (this.state.verificationStatus !== prevState.verificationStatus) {
            DataDogService.log(
                `Verification Call Status: ${this.state.verificationStatus}`
            );
        }
    }

    componentWillUnmount() {
        // Cancel request on component dismount
        this.source.cancel('Cancelled');
    }

    // Set document title based on submission state
    setDocumentTitle() {
        const { t } = this.props;
        if (
            this.state.validation.validated &&
            !this.state.processing &&
            !this.state.expired &&
            !this.state.failed &&
            this.props.completed !== 1
        ) {
            document.title = t('idpal_doc_title_id_preview');
        } else if (
            this.state.processing ||
            (!this.state.validation.messages &&
                !this.state.classification.messages &&
                this.props.completed !== 1)
        ) {
            document.title = t('idpal_doc_title_id_preview_processing');
        } else if (this.props.completed === 1) {
            document.title = t('idpal_doc_title_message_complete');
        } else {
            document.title = t('idpal_doc_title_id_preview_retry');
        }
    }

    /**
     * Classify uploaded document.
     *
     * @param properties
     */
    classifyUploadedDocument(properties, cancelToken) {
        // Clear any error messages
        clearError(this);

        // Get document classification using cropped or uncropped image, try cropped first
        apiService
            .getDocumentClassification(
                documentService.urlToBlob(
                    this.props.croppedImage || this.getImageData()
                ),
                cancelToken
            )
            .then(response => {
                let document = null;

                if (response.data) {
                    // Set document based on classification
                    document = documentService.findByClassification(
                        response.data.classification
                    );

                    // Set classification response to log to datadog
                    this.setState({
                        classification: {
                            ...this.state.classification,
                            detail: response.detail,
                        },
                    });

                    if (document) {
                        // Update card type to the classified card type c
                        this.props.setCardType(document.id);

                        // Check if it is an allowed cardType
                        if (this.props.isDocSelectionEnabled) {
                            this.setState({
                                isAcceptedDoc: this.isAcceptedDoc(
                                    document.name,
                                    allowedDocumentsTypes
                                ),
                            });
                        }
                    }
                    // Log outcome
                    logService.log('Document classified.', document);
                } else {
                    // Could not get classification, fall back to selected document
                    document = documentService.findById(
                        this.props.location.state.properties.documentId
                    );

                    logService.log(
                        'Document not classified, fallback to manually selected',
                        document
                    );
                }

                // Only set classification state if we have document or if doc is a paper licence
                if (document || properties.document.name === 'Paper Licence') {
                    if (document) {
                        properties.document = document;
                        this.setState({
                            currentDocumentRegion: response.data
                                ? response.data.classification.documentRegion
                                : this.state.currentDocumentRegion,
                        });
                    }

                    this.setState({
                        classification: {
                            ...this.state.classification,
                            classified: true,
                        },
                        document: properties.document,
                    });
                } else {
                    // set error message if document is not classifiable
                    this.setState({
                        classification: {
                            ...this.state.classification,
                            messages: i18next.t('idpal_classification_error'),
                        },
                    });
                }

                // Only validate if classification is successful
                if (this.state.classification.classified) {
                    // Set validation results
                    this.validateUploadedDocument(properties);
                    // Set the callType to verify to display the loading messages
                    this.setState({ callType: 'verify' });
                } else {
                    this.setState({
                        processing: false,
                    });
                }
            })
            .catch(error => {
                if (error === 'Cancelled') {
                    logService.error(error);
                } else {
                    DataDogService.createError(
                        'Could not retrieve document classification.'
                    );
                    this.setState({
                        navigation: {
                            action: 'error',
                            props: {
                                retryAction: () =>
                                    this.classifyUploadedDocument(
                                        properties,
                                        cancelToken
                                    ),
                                error: error,
                            },
                        },
                    });
                }
            });
    }

    /**
     * Validating uploaded document.
     *
     * @param properties
     */
    validateUploadedDocument(properties) {
        // Set validation results
        imageValidatorService(properties).then(response => {
            this.setState({
                validation: response,
                processing: false,
            });
        });
    }

    processVerification(response) {
        this.showSpinner();

        // Update the sessionId value in selectStorage(areCookiesEnabled()) if the one is returned from the response
        if (response.data && response.data.hasOwnProperty('sessionid')) {
            selectStorage(areCookiesEnabled()).setItem(
                'sessionid',
                response.data.sessionid
            );
        }

        // Handle documents which require two pages to be submitted
        if (this.state.document.pages.single === false) {
            // Set relevant states
            this.props.decrementSidesLeft();
            this.props.setCardOrientation(1);

            // Capture second document
            if (this.props.sidesLeft === 1) {
                selectStorage(areCookiesEnabled()).setItem(
                    'documentRegion',
                    this.state.currentDocumentRegion
                );
                this.setState({
                    navigation: {
                        action: 'next',
                        props: {
                            documentId: this.state.document.id,
                            sidesLeft: this.props.sidesLeft,
                        },
                    },
                });
            } else {
                // Handle UI transitions
                if (
                    response.code === 200 &&
                    response.data.hasOwnProperty('verification')
                ) {
                    if (
                        response.data.verification.status !== 'Passed' &&
                        this.props.submissionAttempts.remaining > 0
                    ) {
                        this.setState({
                            expired: response.data.verification.expired,
                        });
                        this.setState({ failed: true });
                        this.setState({
                            verificationStatus: 'Failed to verify',
                        });
                        this.hideSpinner();
                    } else {
                        this.documentCheckComplete();
                    }

                    // Clear document region once back image has been uploaded
                    selectStorage(areCookiesEnabled()).removeItem(
                        'documentRegion'
                    );
                } else if (response.code === 202) {
                    // Successful response, but no verification data, accept document and proceed.
                    this.documentCheckComplete();
                } else {
                    this.setState({
                        navigation: {
                            action: 'error',
                        },
                    });
                }
            }
        }

        // Handle documents which require only page to be submitted
        if (
            this.state.document.pages.single === true &&
            this.state.document.pages.number === 1
        ) {
            // Set state
            this.props.decrementSidesLeft();

            // Handle UI transitions
            if (
                response.code === 200 &&
                response.data.hasOwnProperty('verification')
            ) {
                // Failed verification, recapture if attempts remaining
                if (
                    response.data.verification.status !== 'Passed' &&
                    this.props.submissionAttempts.remaining > 0
                ) {
                    this.setState({
                        expired: response.data.verification.expired,
                    });
                    this.setState({ failed: true });
                    this.setState({ verificationStatus: 'Failed to verify' });
                    this.hideSpinner();
                } else {
                    this.documentCheckComplete();
                }
            } else if (
                response.code === 200 &&
                this.state.document.name === 'Paper Licence'
            ) {
                // No verification for Paper Licence
                this.documentCheckComplete();
            } else if (response.code === 202) {
                // Successful response, but no verification data, accept document and proceed.
                this.documentCheckComplete();
            } else {
                this.setState({
                    navigation: {
                        action: 'error',
                    },
                });
            }
        }
    }

    setSubmissionType() {
        let submissionType = null;

        // Handle Paper licenses
        if (this.state.document.name === 'Paper Licence') {
            submissionType = 'paperLicence';
        } else if (
            // Handle two sided documents
            this.state.document.pages.single === false &&
            this.state.document.pages.number === 2
        ) {
            submissionType = 'processDLDuplex';
        } else if (
            // Handle single sided documents
            this.state.document.pages.single === true &&
            this.state.document.pages.number === 1
        ) {
            submissionType = 'postImage';
        } else {
            return submissionType;
        }

        return submissionType;
    }

    /**
     * Upload document to the API
     *
     * @param blobData
     * @param cropped
     */
    uploadDocument(blobData, cropped, cancelToken) {
        this.showSpinner();
        clearError(this);

        const submissionType = this.setSubmissionType();

        const documentRegion =
            selectStorage(areCookiesEnabled()).getItem('documentRegion');

        apiService[submissionType](
            this.props.instanceID,
            this.props.orientation,
            blobData,
            cropped,
            documentRegion,
            cancelToken
        )
            .then(response => {
                this.processVerification(response);
            })
            .catch(error => {
                // catch if user cancelled the request
                if (error === 'Cancelled') {
                    logService.error(error);
                } else {
                    DataDogService.createError(
                        'Unable to submit document for validation.'
                    );
                    this.hideSpinner();
                    this.setState({
                        navigation: {
                            action: 'error',
                            props: {
                                retryAction: () =>
                                    this.uploadDocument(
                                        blobData,
                                        cropped,
                                        cancelToken
                                    ),
                                error: error,
                            },
                        },
                    });
                }
            });
    }

    applyCropSettings() {
        let isCropped = false;

        if (this.state.document.name === 'Passport') {
            isCropped = this.props.cropSettings.passport;
        } else if (
            [
                'Drivers License',
                'Identification Card',
                'Paper Licence',
            ].includes(this.state.document.name)
        ) {
            isCropped = this.props.cropSettings.id_card;
        } else {
            return isCropped;
        }
        return isCropped;
    }

    /**
     * Proceed to next step of user flow.
     */
    proceedToNextStep() {
        const cropped = this.applyCropSettings();

        const image = documentService.urlToBlob(
            cropped && this.props.croppedImage
                ? this.props.croppedImage
                : this.getImageData()
        );
        this.uploadDocument(image, cropped, this.source.token);
    }

    /**
     * Retry document upload.
     */
    retryDocumentUpload(thisSideOnly = false) {
        // Reset document validation items
        this.setState({
            classification: {
                messages: '',
                classified: false,
            },
            validation: {
                messages: '',
                validated: false,
            },
            expired: false,
            failed: false,
        });

        // Rest is expired two page doc
        this.props.isExpiredTwoPagePassport(false);

        if (!thisSideOnly) {
            this.props.resetIDProperties();
        }

        // Check if we have an expired two page document
        if (this.isTwoPagePassport() && this.state.expired) {
            this.props.isExpiredTwoPagePassport(true);
        }

        this.setState({
            navigation: {
                action: 'next',
                props: {
                    isRetry: true,
                    documentId: this.state.isAcceptedDoc
                        ? this.state.document.id
                        : this.props.userSelectedCardType, // Use user selected cardType when document selection is enabled and wrong doc type submitted.
                    sidesLeft: this.props.sidesLeft,
                },
            },
        });
    }

    /**
     * Document check complete, continue to success or passive liveness
     */
    documentCheckComplete() {
        this.props.setRetryLimit(0);
        this.props.completeStage('document_upload');
        this.setState({
            navigation: {
                action: 'next',
            },
        });
    }

    /**
     * If uncropped image is too large in height, width, or file-size, use downscaled version
     */
    getImageData() {
        if (
            this.props.location.state.imageSize > environment.maxFileSize ||
            this.props.location.state.originalHeight > environment.maxHeight ||
            this.props.location.state.originalWidth > environment.maxWidth
        ) {
            return this.props.downscaledImage;
        } else {
            return this.props.uncroppedImage;
        }
    }

    /**
     * Show spinner.
     */
    showSpinner() {
        this.setState({ processing: true });
    }

    /**
     * Hide spinner.
     */
    hideSpinner() {
        this.setState({ processing: false });
    }

    /**
     * Checks the orientation of the preview image.
     */
    isImageOrientationPortrait() {
        if (
            !isIdPalCameraEnabled(this.props.captureMethod) ||
            this.props.bypassAcuant
        ) {
            return false;
        }

        const imageHeight = this.props.location.state.height;
        const imageWidth = this.props.location.state.width;
        return imageHeight > imageWidth;
    }

    isTwoPagePassport() {
        return (
            this.props.onePagePassport === 0 &&
            this.state.document.name === 'Passport'
        );
    }

    selectModalImage(validationState) {
        let image = null;

        switch (validationState) {
            case 'glare':
                image = documentGlare;
                break;
            case 'sharpness':
                image = documentNotFound;
                break;
            case 'dpi':
                image = documentError;
                break;
            default:
                image = documentError;
        }

        return image;
    }

    isAcceptedDoc(docType, allowedDocumentsTypes) {
        const docTypeKey = getKeyByValue(allowedDocumentsTypes, docType);

        return this.props.acceptedDocuments.includes(docTypeKey);
    }

    getCurrentDocName() {
        const { t } = this.props;

        const docTypeKey = getKeyByValue(
            allowedDocumentsTypes,
            this.state.document.name
        );

        // Translate name if possible
        return t(titleMap[docTypeKey]) || this.state.document.name;
    }

    getListOfAcceptedDocumentTitles() {
        const { t } = this.props;

        const acceptedDocuments = this.props.acceptedDocuments.map(
            title => t(titleMap[title]) || title
        );

        return acceptedDocuments;
    }

    /**
     * Render component.
     */
    render() {
        // Instantiate translation service
        const { t } = this.props;

        // Set props
        const data = this.props.location.state;
        const cardImage = this.props.croppedImage ?? this.getImageData();

        if (isSubmissionComplete()) {
            return (
                <SubmissionCompleteState companyName={this.props.companyName} />
            );
        }

        // Document upload is already completed
        if (this.props.completed && this.state.navigation.action === 'load') {            
            return (
                <DocumentUploadCompletedState
                    onContinue={() => this.setState({ navigation: { action: 'next' } })}
                    navigationAction={this.state.navigation.action}
                    navigationProps={this.state.navigation.props}
                />
            );
        }

        // Render loader component (while state is processing)
        if (
            this.state.processing ||
            (!this.state.validation.messages &&
                !this.state.classification.messages)
        ) {
            return (
                <Fragment>
                    <Processing
                        document={data}
                        callType={this.state.callType}
                        showMessages={true}
                    />
                    <Navigator
                        page={'document_review'}
                        action={this.state.navigation.action}
                        propsToPass={this.state.navigation.props}
                    />
                </Fragment>
            );
        }

        // Render component
        return (
            <div className="root-layout-container processed-image-result-page">
                {/* User Image capture error modals */}
                <ModalPopup
                    classification={this.state.classification}
                    validation={this.state.validation}
                    isAcceptedDoc={this.state.isAcceptedDoc}
                    expired={this.state.expired}
                    failed={this.state.failed}
                    submissionAttempts={this.props.submissionAttempts}
                />
                
                <Header />
                <SubmissionFlowStepper/>
                    {/*Normal Submission Process*/}
                    {this.props.submissionAttempts.remaining > 0 &&
                        !this.state.failed && (
                          <DocumentReviewState
                            data={data}
                            submissionAttempts = {this.props.submissionAttempts}
                            isFailed = {this.state.failed}
                            classification = {this.state.classification}
                            pageFocusRef = {this.pageFocusRef}
                            validation = {this.state.validation}
                            cardImage = {cardImage}                           
                            isAcceptedDoc = {this.state.isAcceptedDoc}
                            isImageOrientationPortrait = {this.isImageOrientationPortrait}                            
                            isTwoPagePassport = {this.isTwoPagePassport}
                            getImageData = {this.getImageData}                           
                            proceedToNextStep = {this.proceedToNextStep}                            
                            retryDocumentUpload = {this.retryDocumentUpload}
                            />
                        )}

                    {/*Submitted ID Failed Verification*/}
                    {this.state.failed && (
                       <FailedVerification
                            isExpired = {this.state.expired}
                            pageFocusRef = {this.pageFocusRef}
                            cardImage = {cardImage}                           
                            isImageOrientationPortrait = {this.isImageOrientationPortrait}                          
                            retryDocumentUpload = {this.retryDocumentUpload}
                       />
                    )}

                    {/* Out of retry attempts. Submit whatever regardless of validation or classification */}
                    {this.props.submissionAttempts.remaining === 0 && (
                        <OutOfRetryAttemptsState
                            pageFocusRef={this.pageFocusRef}
                            cardImage = {cardImage}                            
                            isImageOrientationPortrait = {this.isImageOrientationPortrait}
                            proceedToNextStep = {this.proceedToNextStep}
                        />
                    )}

                <Navigator
                    page={'document_review'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        orientation: state.idProperties.orientation,
        bypassAcuant: state.config.bypassAcuant,
        cardType: state.idProperties.cardType,
        sidesLeft: state.idProperties.sidesLeft,
        frontSubmitted: state.config.frontSubmitted,
        backSubmitted: state.config.backSubmitted,
        submissionAttempts: state.submissionState.submissionAttempts,
        completed:
            state.submissionState.submissionState.document_upload.completed,
        screens: state.submissionState.screens,
        cropSettings: state.config.cropSettings,
        uncroppedImage: state.idProperties.uncroppedImage,
        downscaledImage: state.idProperties.downscaledImage,
        croppedImage: state.idProperties.croppedImage,
        captureMethod:
            state.submissionState.screens.document_upload.capture_method,
        companyName: state.config.profile.data.company_branding.name,
        onePagePassport:
            state.submissionState.screens.document_upload.one_page_passport,
        isDocSelectionEnabled: state.submissionState.isDocSelectionEnabled,
        acceptedDocuments: state.config.profile.data.accepted_documents,
        userSelectedCardType: state.idProperties.userSelectedCardType,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            decrementSidesLeft,
            incrementSidesLeft,
            resetIDProperties,
            setCardOrientation,
            setRetryLimit,
            setCardType,
            completeStage,
            submissionAttempt,
            isExpiredTwoPagePassport,
        },
        dispatch
    );
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(ProcessedImageResult)
);