/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n';

// Services
import apiService from '../services/api/api';
import logService from '../services/shared/logService';
import DataDogService from '../services/shared/datadogService';
import { regenerateSession } from '../services/shared/authenticationService';

// Helpers
import { getCompanyName, getLanguage } from '../services/shared/helpers';

// Components
import Header from './Header';
import Navigator from './Navigator';
import CustomButton from './Button';
import CustomSelectInput from './Inputs/CustomSelectInput';

// Images
import loadingGif from '../assets/gifs/loading.gif';
import { imageAlt } from '../config/accessabilityRules';

// Config
import LANGUAGES from '../config/languages';
import { ACTION_LABELS } from '../config/dataDogActionLabels';
import Checkbox from './Checkbox';

// Constants
const ACTION = ACTION_LABELS.consent;

class Consent extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.state = {
            consent: false,
            loading: false,
            refreshAttempted: false,
            navigation: {
                action: 'load',
                props: null,
            },
        };
        this.continue = this.continue.bind(this);
        this.setConsent = this.setConsent.bind(this);
    }

    componentDidMount() {
        // Sets focus to primary heading on first render
        const { t } = this.props;
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        document.title = t('idpal_consent_title');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    setConsent(e) {
        this.setState({
            consent: e.target.checked,
        });
    }

    continue() {
        if (this.state.consent) {
            this.setState({
                loading: true,
            });

            apiService
                .postConsent(this.state.consent)
                .then(response => {
                    DataDogService.log('Consent successfully sent');
                    this.setState({
                        navigation: {
                            action: 'next',
                        },
                    });
                })
                .catch(error => {
                    if (error.status === 403 && !this.state.refreshAttempted) {
                        regenerateSession(this.continue, [], this);
                    } else {
                        DataDogService.createError('Unable to send consent');
                        this.setState({ loading: false });
                        logService.error(error);
                        this.setState({
                            navigation: {
                                action: 'error',
                                props: {
                                    retryAction: () => this.continue(),
                                    error: error,
                                },
                            },
                        });
                    }
                });
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <Header />
                <div className='o-site-wrap'>
                    {this.state.loading === true && (
                        <div className='u-display-loading u-text-center'>
                            <img
                                alt={imageAlt.loading}
                                src={loadingGif}
                                className='capture'
                            />
                        </div>
                    )}

                    {this.state.loading !== true && (
                        <div className='consent'>
                            <h1 className='consent__header'>
                                {t('idpal_before_you_continue')}
                            </h1>
                            <p className='consent__paragraph'>
                                {t('idpal_consent_message_1')}
                                <a
                                    href={this.props.policies.privacy_policy}
                                    target={'_blank'}
                                    rel='noreferrer'
                                    data-dd-action-name={ACTION.privacyLink}
                                    aria-label={t('idpal_privacy_policy_link')}
                                >
                                    {t('idpal_privacy_policy_link')}
                                </a>
                                <br />
                                <br />
                                {t('idpal_consent_message_2')}
                            </p>

                            <div class='consent__input'>
                                <Checkbox
                                    id='consent'
                                    name='consent'
                                    className='consent__checkbox'
                                    actionDataLabel={ACTION.clickConsent}
                                    handleChange={this.setConsent}
                                ></Checkbox>
                                <label className='consent__label' for='consent'>
                                    {t('idpal_i_consent')}
                                </label>
                            </div>

                            <CustomButton
                                className='consent__button btn'
                                label={t('idpal_continue')}
                                isDisabled={!this.state.consent}
                                handleClick={this.continue}
                                actionDataLabel={ACTION.consentContinue}
                            />
                        </div>
                    )}
                </div>

                <Navigator
                    page={'consent'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        submission: state.submissionState.details,
        branding: state.config.profile.data.company_branding,
        policies: state.config.profile.data.policies,
    };
}

export default withTranslation('translation')(
    connect(mapStateToProps)(Consent)
);
