import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { ModalPopupOverlay } from "../../../components/ModalPopupOverlay";
import FailedToAcceptDocumentIcon from "./DynamicColoredIcons/FailedToAcceptDocumentIcon";
import LowResolutionWarningIcon from "./DynamicColoredIcons/LowResolutionWarningIcon";
import ExpiredDocumentIcon from "./DynamicColoredIcons/ExpiredDocumentIcon";
import BlurryImageWarningIcon from "./DynamicColoredIcons/BlurryImageWarningIcon";
import GlareImageWarningIcon from "./DynamicColoredIcons/GlareImageWarningIcon";

const ModalPopup = ({
    classification,
    validation,
    isAcceptedDoc,
    expired,
    submissionAttempts,
    failed,
 }) => {
    const { t } = useTranslation();

    const [validationErrorDetails, setValidationErrorDetails] = useState(null);
    const getIconByValidationType = validationErrorType => {
        let Icon = null;
        let modalTitle = '';
        let modalText = '';
        switch (validationErrorType) {
            case 'glare':
                Icon = GlareImageWarningIcon;
                modalTitle = t('idpal_popup_validation_glare_title');
                modalText = t('idpal_popup_validation_glare_text');
                break;
            case 'sharpness':
                Icon = BlurryImageWarningIcon;
                modalTitle = t('idpal_popup_validation_blurry_title');
                modalText = t('idpal_popup_validation_blurry_text');
                break;
            case 'dpi':
                Icon = LowResolutionWarningIcon;
                modalTitle = t('idpal_popup_validation_resolution_title');
                modalText = t('idpal_popup_validation_resolution_text');
                break;
            default:
                Icon = FailedToAcceptDocumentIcon;
                modalTitle = t('idpal_popup_validation_glare_title');
                modalText = t('idpal_popup_validation_glare_text');
        }
        return {
            Icon,
            modalTitle,
            modalText
        };
    };

    useEffect(() => {        
        if(!validation.validated && !failed) {
            setValidationErrorDetails(getIconByValidationType(validation.type));
        }
    }, [validation, failed]);

    return (        
    <>
        {submissionAttempts.remaining > 0 &&
            !failed && (
                <>
                    {/* Failed classification */}
                    {!classification.classified &&
                        classification.messages && (
                            <ModalPopupOverlay
                                Icon={FailedToAcceptDocumentIcon}
                                popupTitle={t('idpal_popup_title_doc_not_recognized')}                               
                                popupText={<Trans i18nKey="idpal_popup_text_retake_a_picture" />}                                
                            />                                    
                        )}

                    {/* Failed on image quality */}
                    {   classification.classified && 
                        !validation.validated &&
                        validationErrorDetails && (
                            <ModalPopupOverlay
                                Icon={validationErrorDetails.Icon}
                                popupTitle={validationErrorDetails.modalTitle}
                                popupText={validationErrorDetails.modalText}                               
                            />                                       
                        )}    
                    {/* Document Type not Accepted */}
                    {!isAcceptedDoc && (
                        <ModalPopupOverlay
                            Icon={FailedToAcceptDocumentIcon}
                            popupTitle={t('idpal_popup_title_doc_not_accepted')}
                            popupText={<Trans i18nKey="idpal_popup_text_retake_a_picture" />}                           
                        />                                   
                    )}
                </>
            )}

        {/* Failed verification */}
        {failed && (
            <>
                {expired ? (
                    <ModalPopupOverlay 
                        Icon={ExpiredDocumentIcon}
                        popupTitle={t('idpal_popup_title_doc_expired')}
                        popupText={t('idpal_popup_text_expired_doc_warning')}
                    />                   
                ) : (
                    <ModalPopupOverlay
                        Icon={FailedToAcceptDocumentIcon}
                        popupTitle={t('idpal_no_image_detected')}
                        popupText={t('idpal_no_image_user_message')}
                    />                   
                )}
            </>
        )}
    </>);
};

export default ModalPopup;

