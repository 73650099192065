import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import CustomButton from "./CustomButton";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        brandingColor: state.config.profile.data.company_branding.primary_color
    }
};
export const ModalPopupOverlay = connect(mapStateToProps)
(({ Icon = null,  popupTitle = "", popupText = "", initialState = true, brandingColor }) => {
    const [showModal, setShowModal] = useState(initialState);
    const [isContentRolledOut, setIsContentRolledOut] = useState(false);
    useEffect(() => {
        if(showModal){
            setTimeout(() => {
                setIsContentRolledOut(true);
            }, 100);
        }
    }, [showModal, initialState]);
    const closeModal = () => {
        setIsContentRolledOut(false);
        setShowModal(false);
    };
    return createPortal(
        <div
            id="modal-popup"
            style={{display: showModal ? 'flex' : 'none'}}
            className={`${showModal ? 'active' : ''}`}
            onClick={closeModal}
        >
            <div 
                className={`content-wrapper ${isContentRolledOut ? 'active' : ''}`}
                onClick={ e => e.stopPropagation()}
            >
                <div                   
                    className="popup-icon">
                    {Icon && (<Icon fill={`#${brandingColor}`}/>)}
                </div>
                <h1 className="popup-title">
                       {popupTitle}
                </h1>
                <p className="popup-text">
                        {popupText}
                </p>
                <CustomButton
                    label={'Continue'}
                    handleClick={closeModal}
                />
            </div>
        </div>, document.body);
});