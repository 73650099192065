const FailedToAcceptDocumentIcon = ({fill = "#0047FF"}) => (
    <svg width="73" height="75" viewBox="0 0 73 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="32.5" cy="42.5" r="32.5" fill={fill} fillOpacity="0.24"/>
        <path d="M37.5 48.8125C35.6584 48.8125 34.1608 47.3703 34.072 45.5398L33.1734 25.7819C33.129 24.8389 33.4619 23.9403 34.1164 23.2525C34.7709 22.5647 35.6473 22.1875 36.6014 22.1875H38.3986C39.3415 22.1875 40.229 22.5647 40.8836 23.2525C41.5381 23.9403 41.8709 24.8389 41.8265 25.7819L40.928 45.5398C40.8392 47.3814 39.3415 48.8125 37.5 48.8125ZM36.6014 24.4062C36.2686 24.4062 35.958 24.5394 35.725 24.7834C35.492 25.0275 35.37 25.3381 35.3922 25.6709L36.2908 45.4289C36.324 46.0723 36.8565 46.5827 37.5 46.5827C38.1434 46.5827 38.687 46.0723 38.7092 45.4289L39.6078 25.6709C39.6189 25.3381 39.508 25.0164 39.275 24.7834C39.042 24.5394 38.7314 24.4062 38.3986 24.4062H36.6014Z" fill="black"/>
        <path d="M37.5 57.6875C35.6695 57.6875 34.1719 56.1898 34.1719 54.3594C34.1719 52.5289 35.6695 51.0312 37.5 51.0312C39.3305 51.0312 40.8281 52.5289 40.8281 54.3594C40.8281 56.1898 39.3305 57.6875 37.5 57.6875ZM37.5 53.25C36.8898 53.25 36.3906 53.7492 36.3906 54.3594C36.3906 54.9695 36.8898 55.4688 37.5 55.4688C38.1102 55.4688 38.6094 54.9695 38.6094 54.3594C38.6094 53.7492 38.1102 53.25 37.5 53.25Z" fill="black"/>
        <path d="M15.3679 40.947C13.8259 40.947 12.4281 39.9042 12.0398 38.34L7.29167 19.1367C7.0587 18.2159 7.21401 17.2729 7.72432 16.4742C8.23463 15.6754 9.02229 15.1318 9.95417 14.9432L11.7181 14.5882C12.6389 14.3996 13.5818 14.5882 14.3584 15.1429C15.1349 15.6865 15.6342 16.4964 15.7784 17.4393L18.7737 36.9976C19.051 38.817 17.8529 40.5254 16.0557 40.8804C15.8228 40.9248 15.6009 40.947 15.379 40.947H15.3679ZM12.3837 16.7515C12.306 16.7515 12.2173 16.7515 12.1396 16.7737L10.3757 17.1287C10.0429 17.1953 9.76557 17.3839 9.58807 17.6723C9.41057 17.9496 9.3551 18.2935 9.43276 18.6153L14.192 37.8185C14.3473 38.4509 14.9685 38.8392 15.6009 38.7171C16.2332 38.5951 16.6548 37.9849 16.566 37.3415L13.5707 17.7832C13.5153 17.4504 13.3378 17.162 13.0715 16.9734C12.8607 16.8292 12.6278 16.7515 12.3837 16.7515Z" fill="black"/>
        <path d="M17.1429 49.6446C15.5897 49.6446 14.1919 48.5463 13.8813 46.971C13.5263 45.1738 14.6912 43.4099 16.4883 43.0549C17.3647 42.8774 18.2522 43.0549 18.9844 43.5541C19.7277 44.0422 20.2269 44.7966 20.4044 45.673C20.5819 46.5494 20.4044 47.4369 19.9163 48.1691C19.4282 48.9124 18.6738 49.4116 17.7974 49.5891C17.5755 49.6335 17.3537 49.6557 17.1429 49.6557V49.6446ZM17.1429 45.2071C17.0652 45.2071 16.9987 45.2071 16.921 45.2293C16.3219 45.3513 15.9337 45.9393 16.0557 46.5383C16.1777 47.1374 16.7657 47.5257 17.3647 47.4036C17.6532 47.3482 17.9083 47.1818 18.0747 46.9266C18.2412 46.6825 18.2966 46.383 18.2412 46.0946C18.1857 45.8061 18.0193 45.551 17.7641 45.3846C17.5755 45.2625 17.3647 45.196 17.154 45.196L17.1429 45.2071Z" fill="black"/>
        <path d="M53.6858 34.5682C53.4639 34.5682 53.2309 34.546 53.009 34.5017C51.2119 34.1356 50.0137 32.4382 50.2911 30.6189L53.2864 11.0606C53.4306 10.1287 53.9298 9.30776 54.7064 8.76417C55.483 8.22057 56.4259 8.03198 57.3467 8.20948L59.1106 8.56448C60.0425 8.75307 60.8301 9.29667 61.3294 10.0954C61.8397 10.8942 61.995 11.8371 61.762 12.7579L57.0028 31.9612C56.6145 33.5143 55.2278 34.5682 53.6747 34.5682H53.6858ZM56.67 10.3728C56.4259 10.3728 56.1819 10.4504 55.9822 10.5946C55.7048 10.7832 55.5273 11.0717 55.483 11.4045L52.4876 30.9628C52.3878 31.6062 52.8094 32.2053 53.4528 32.3384C54.0851 32.4715 54.7064 32.0721 54.8728 31.4398L59.632 12.2365C59.7097 11.9148 59.6542 11.582 59.4767 11.2935C59.2992 11.0162 59.0219 10.8165 58.689 10.7499L56.9251 10.3949C56.8475 10.3839 56.7587 10.3728 56.6811 10.3728H56.67Z" fill="black"/>
        <path d="M51.9219 43.2656C51.7111 43.2656 51.4892 43.2434 51.2673 43.1991C49.4702 42.8441 48.2942 41.0802 48.6603 39.283C49.0264 37.4858 50.7792 36.3099 52.5764 36.6759C53.4528 36.8534 54.1961 37.3527 54.6953 38.0959C55.1834 38.8392 55.3609 39.7267 55.1834 40.592C54.8617 42.1673 53.475 43.2656 51.9219 43.2656ZM50.8347 39.7156C50.7127 40.3147 51.1009 40.9027 51.7 41.0247C52.2991 41.1467 52.887 40.7584 53.0091 40.1594C53.0645 39.8709 53.0091 39.5714 52.8427 39.3274C52.6763 39.0833 52.4322 38.9169 52.1327 38.8503C51.5336 38.7283 50.9456 39.1166 50.8236 39.7156H50.8347Z" fill="black"/>
        <path d="M45.2656 55.4688C45.8783 55.4688 46.375 54.9721 46.375 54.3594C46.375 53.7467 45.8783 53.25 45.2656 53.25C44.6529 53.25 44.1562 53.7467 44.1562 54.3594C44.1562 54.9721 44.6529 55.4688 45.2656 55.4688Z" fill="black"/>
        <path d="M48.5938 55.4688C49.2064 55.4688 49.7031 54.9721 49.7031 54.3594C49.7031 53.7467 49.2064 53.25 48.5938 53.25C47.9811 53.25 47.4844 53.7467 47.4844 54.3594C47.4844 54.9721 47.9811 55.4688 48.5938 55.4688Z" fill="black"/>
        <path d="M51.9219 55.4688C52.5346 55.4688 53.0312 54.9721 53.0312 54.3594C53.0312 53.7467 52.5346 53.25 51.9219 53.25C51.3092 53.25 50.8125 53.7467 50.8125 54.3594C50.8125 54.9721 51.3092 55.4688 51.9219 55.4688Z" fill="black"/>
        <path d="M29.7344 11.0938C29.1242 11.0938 28.625 10.5945 28.625 9.98438V8.875C28.625 8.26484 29.1242 7.76562 29.7344 7.76562C30.3445 7.76562 30.8438 8.26484 30.8438 8.875V9.98438C30.8438 10.5945 30.3445 11.0938 29.7344 11.0938Z" fill="black"/>
        <path d="M33.0625 13.3125H31.9531C31.343 13.3125 30.8438 12.8133 30.8438 12.2031C30.8438 11.593 31.343 11.0938 31.9531 11.0938H33.0625C33.6727 11.0938 34.1719 11.593 34.1719 12.2031C34.1719 12.8133 33.6727 13.3125 33.0625 13.3125Z" fill="black"/>
        <path d="M29.7344 16.6406C29.1242 16.6406 28.625 16.1414 28.625 15.5312V14.4219C28.625 13.8117 29.1242 13.3125 29.7344 13.3125C30.3445 13.3125 30.8438 13.8117 30.8438 14.4219V15.5312C30.8438 16.1414 30.3445 16.6406 29.7344 16.6406Z" fill="black"/>
        <path d="M27.5156 13.3125H26.4062C25.7961 13.3125 25.2969 12.8133 25.2969 12.2031C25.2969 11.593 25.7961 11.0938 26.4062 11.0938H27.5156C28.1258 11.0938 28.625 11.593 28.625 12.2031C28.625 12.8133 28.1258 13.3125 27.5156 13.3125Z" fill="black"/>
        <path d="M13.5487 58.3421C13.2602 58.3421 12.9829 58.2311 12.761 58.0204C12.3284 57.5877 12.3284 56.8888 12.761 56.4561L13.5487 55.6685C13.9813 55.2358 14.6802 55.2358 15.1129 55.6685C15.5455 56.1011 15.5455 56.8 15.1129 57.2327L14.3252 58.0204C14.1034 58.2422 13.826 58.3421 13.5376 58.3421H13.5487Z" fill="black"/>
        <path d="M14.3363 62.2581C14.0479 62.2581 13.7705 62.1472 13.5487 61.9364L12.761 61.1487C12.3284 60.7161 12.3284 60.0172 12.761 59.5845C13.1937 59.1518 13.8926 59.1518 14.3252 59.5845L15.1129 60.3722C15.5455 60.8048 15.5455 61.5037 15.1129 61.9364C14.891 62.1583 14.6137 62.2581 14.3252 62.2581H14.3363Z" fill="black"/>
        <path d="M9.63253 62.2581C9.3441 62.2581 9.06675 62.1472 8.84488 61.9364C8.41222 61.5037 8.41222 60.8048 8.84488 60.3722L9.63253 59.5845C10.0652 59.1518 10.7641 59.1518 11.1968 59.5845C11.6294 60.0172 11.6294 60.7161 11.1968 61.1487L10.4091 61.9364C10.1872 62.1583 9.90988 62.2581 9.62144 62.2581H9.63253Z" fill="black"/>
        <path d="M10.4202 58.3421C10.1318 58.3421 9.85441 58.2311 9.63253 58.0204L8.84488 57.2327C8.41222 56.8 8.41222 56.1011 8.84488 55.6685C9.27753 55.2358 9.97644 55.2358 10.4091 55.6685L11.1968 56.4561C11.6294 56.8888 11.6294 57.5877 11.1968 58.0204C10.9749 58.2422 10.6975 58.3421 10.4091 58.3421H10.4202Z" fill="black"/>
        <path d="M64.4467 44.8853C64.1583 44.8853 63.881 44.7744 63.6591 44.5636C63.2264 44.1309 63.2264 43.432 63.6591 42.9994L64.4467 42.2117C64.8794 41.779 65.5783 41.779 66.011 42.2117C66.4436 42.6444 66.4436 43.3433 66.011 43.7759L65.2233 44.5636C65.0014 44.7854 64.7241 44.8853 64.4356 44.8853H64.4467Z" fill="black"/>
        <path d="M65.2344 48.8125C64.946 48.8125 64.6686 48.7016 64.4467 48.4908L63.6591 47.7032C63.2264 47.2705 63.2264 46.5716 63.6591 46.1389C64.0917 45.7063 64.7906 45.7063 65.2233 46.1389L66.011 46.9266C66.4436 47.3593 66.4436 48.0582 66.011 48.4908C65.7891 48.7127 65.5117 48.8125 65.2233 48.8125H65.2344Z" fill="black"/>
        <path d="M60.5307 48.8125C60.2422 48.8125 59.9649 48.7016 59.743 48.4908C59.3104 48.0582 59.3104 47.3593 59.743 46.9266L60.5307 46.1389C60.9633 45.7063 61.6622 45.7063 62.0949 46.1389C62.5275 46.5716 62.5275 47.2705 62.0949 47.7032L61.3072 48.4908C61.0854 48.7127 60.808 48.8125 60.5196 48.8125H60.5307Z" fill="black"/>
        <path d="M61.3072 44.8853C61.0188 44.8853 60.7414 44.7744 60.5196 44.5636L59.7319 43.7759C59.2992 43.3433 59.2992 42.6444 59.7319 42.2117C60.1646 41.779 60.8635 41.779 61.2961 42.2117L62.0838 42.9994C62.5164 43.432 62.5164 44.1309 62.0838 44.5636C61.8619 44.7854 61.5846 44.8853 61.2961 44.8853H61.3072Z" fill="black"/>
    </svg>
)
    
export default FailedToAcceptDocumentIcon;