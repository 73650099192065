import { useTranslation } from "react-i18next";
import { imageAlt } from "../../../config/accessabilityRules";
import CustomButton from "../../../components/CustomButton";
import { ACTION_LABELS } from '../../../config/dataDogActionLabels';

const ACTION = ACTION_LABELS.processResults;

const FailedVerification = ({ 
    isExpired,
    pageFocusRef,
    isImageOrientationPortrait,
    cardImage,
    retryDocumentUpload,
 }) => {
    const { t } = useTranslation();
    return (
        <>
            <h1
                className={'error-message-text'}
                ref={pageFocusRef}
                tabIndex={0}
            >
            {isExpired? t('idpal_id_verification_expired') : t('idpal_id_verification_failed')}
            </h1>
            <div className={`u-display-upload ${isImageOrientationPortrait() ? 'preview-container' : ''}`}>
                {cardImage && (
                    <img
                        id='document-image'
                        alt={imageAlt.upLoadedDocument}
                        src={cardImage}
                        className='capture'
                    />
                )}
            </div>
            <div className='document-review-cta-container'>
                <CustomButton
                    id={'retry'}
                    type='customHover'
                    className={'btn outline'}
                    label={t('idpal_retry')}
                    handleClick={() => retryDocumentUpload()}
                    actionDataLabel={ACTION.retryUploadButton}
                    outlined
                />
            </div>
        </>
)};

export default FailedVerification;