const TextInput = ({ label, isRequired, placeholder, error, config, onChange, defaultValue, inputProps = {}}) => {
  
    return (            
        <div style={{position: 'relative'}}>
               <input
                    id={config.id}
                    type="text"
                    placeholder={placeholder}
                    className={`text-input ${error ? 'error': ''}`}
                    onChange={onChange}
                    aria-invalid={error ? true : false}
                    defaultValue={defaultValue}
                    {...inputProps}
               />
               <label
                    id={config.id}
                    className={`text-input-label ${isRequired? 'required': ''}`}
                    style={{ position: 'absolute', color: error ? 'red': '#000' }}
               >
                {label}
               </label>
                {error && (<span className="error-text">{error}</span>)}
        </div>
    );
};
export default TextInput;