import { useTranslation } from 'react-i18next';
import StageComplete from '../../../screens/Messages/StageComplete';
import { getCompanyName } from '../../../services/shared/helpers';
import Header from '../../../components/Header';

const SubmissionCompleteState = ({ companyName }) => {
    const { t } = useTranslation();
    return (
        <div className='root-layout-container'>
            <Header />
            <StageComplete
                message={t('idpal_your_submission_is_complete', {
                    company: getCompanyName(companyName),
                })}
                hideContinue={true}
                hideButton={true}
            />
        </div>
    )

};
export default SubmissionCompleteState;