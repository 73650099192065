/**
 * @prettier
 */

/**
 * Defining errors returned by third party services that we do not want to log as errors in DataDog
 * This is to reduce log pollution
 * These will still be logged, just not at error level.
 *
 * @type array
 */

const ERRORS_TO_DOWNGRADE = [
    'IDR&D Liveness error: Permission denied', // User has denied permission to use the camera
    'Request failed with status code 420', // Failed liveness response, successful negative response
    'Video element not found', // We have fallen back to manual liveness, video element is not there to be found
];

/**
 * Console errors that we cannot downgrade, but are not an issue
 * These will not be sent to DataDog
 *
 * @type object
 */
const ERRORS_TO_DISREGARD = [
    {
        message: 'console error: Error: Video element not found', // We have fallen back to manual liveness, video element is not there to be found
        url: '/capture/selfie',
    },
];

export { ERRORS_TO_DOWNGRADE, ERRORS_TO_DISREGARD };
