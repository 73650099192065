import Navigator from "../../../screens/Navigator";
import OutOfRecaptures from "../../../screens/Messages/OutOfRecaptures";
import Header from "../../../screens/Header";

const OutOfRetryAttemptsState = ({ onContinue, message, navigationAction, navigationProps }) => (
        <>
            <Header />
            <OutOfRecaptures
                continue={onContinue}
                message={message}
            />
            <Navigator
                page={'document_capture'}
                action={navigationAction}
                propsToPass={navigationProps}
            />
        </>        
);

export default OutOfRetryAttemptsState;