/**
 * @prettier
 */

import {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import LANGUAGES from '../config/languages';
import { ACTION_LABELS } from '../config/dataDogActionLabels';


// Helpers
import {
    updateUrlLanguageParam,
    getLanguage
} from '../services/shared/helpers';

// Actions
import { setInitializationParams } from '../screens/actions/configActions';

const LanguageSelectInput = ({
    isLanguageChange = false,
    setInitializationParams,
}) => {
    const selectOptionRef = useRef();
    const { t } = useTranslation();
    const [defaultSelectText, setDefaultSelectText] = useState(getLanguage(i18n.language));
    const [selectedOption, setSelectedOption] = useState(i18n.language);
    const [showOptionList, setShowOptionList] = useState(false);
    const [currentFocus, setCurrentFocus] = useState(0);
    const [optionsListState, setOptionsListState] = useState(LANGUAGES);   
    const ACTION = ACTION_LABELS.acuantCamera;
    

    useEffect(() => {
        // Adds Event listener to handle clicking outside the container
        document.addEventListener('mousedown', handleClickOutside);

        // Orders options list so that selected element always displays as first option
        setOptionsListState(orderOptionsList());
        // Removes listeners when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Orders options list so that selected element always displays as first option
        if (selectedOption) {
            setOptionsListState(orderOptionsList());
        }
    }, [selectedOption]);


    // Handles closing the dropdown when clicked outside of the select
    const handleClickOutside = event => {
        if (
            !event.target.classList.contains('custom-select-btn') &&
            !event.target.classList.contains('selected-text')&&
            !event.target.classList.contains('active')
        ) {
            setShowOptionList(false);
        }
    };
    const  handleOptionClick = event => {
        // Sets option name in select text area
        setDefaultSelectText(event.target.getAttribute('data-name'));
        setShowOptionList(false);
        // Resets focus and focus styles  
        selectOptionRef.current.focus();

        // Updates language if passed through props
        if (isLanguageChange) {
            const language = event.target.getAttribute('value');  
            setSelectedOption(language);       
            // Sets the new url param
            updateUrlLanguageParam(language);

            // Updates the init param
            setInitializationParams(window.location.search);

            // Changes language
            i18n.changeLanguage(language);
        }
    }
    // Handles down arrow and up arrow focus change for options dropdown
    const handleOptionKeyDown = event => {        
        const dropdownButtons = document.querySelectorAll('.custom-select-btn');
        if (event.key === 'ArrowDown') {            
            const elementInFocus = dropdownButtons[currentFocus];
            const elementToFocus = dropdownButtons[currentFocus + 1];
            if (elementToFocus) {
                elementToFocus.focus();
                elementInFocus.blur();                
                setCurrentFocus(currentFocus + 1);               
            }
            return;
        }
        if (event.key === 'ArrowUp') {
            const elementInFocus = dropdownButtons[currentFocus];
            const elementToFocus = dropdownButtons[currentFocus - 1];
            if (elementToFocus) {
                elementToFocus.focus();
                elementInFocus.blur();                
                setCurrentFocus(currentFocus-1); 
            }
            return;
        }
        if (event.key === 'Enter' || event.key === 'Space'  ) {
            setCurrentFocus(0);
        }
    }


    // Handles removing focus background on mouse over
    const handleMouseEnterSelect = () => {
        const dropdownOptions = document.querySelectorAll('.custom-select-btn');
        dropdownOptions.forEach(option => {
            option.blur();
        });
    };

    // Handles displaying of the option list
    const handleListDisplay = () => {
        setShowOptionList(!showOptionList);
    };

    // Orders options list so that selected element always displays as first option
    const orderOptionsList = () => {        
        const options  = [...LANGUAGES.sort((a, b) => a.label.localeCompare(b.label))];
        const index = options.findIndex(lang => lang.value === selectedOption);
        options.splice(index, 1);
        const newOptions = [LANGUAGES[index], ...options];
        return newOptions;
    }
    return (
            <div className='language-select-container'>
                <button
                    className={`selected-language-text ${showOptionList ? 'active' : ''}`}
                    onClick={handleListDisplay}
                    ref={selectOptionRef}
                    aria-label={t('idpal_label_select_language')}
                >
                    {defaultSelectText}
                    <span className={`chevron ${showOptionList? '':'down'}`}/>
                </button>

                {showOptionList && (
                    <ul
                        className='select-options'
                        onMouseEnter={handleMouseEnterSelect}
                    >
                        {optionsListState.map((option, index) => {
                            return (
                                <li
                                    key={option.value}
                                    className='option'
                                    onKeyDown={handleOptionKeyDown}
                                >                                   
                                    <button
                                        autoFocus={index === 0}      
                                        className='custom-select-btn'
                                        data-name={option.label}
                                        value={option.value}                                        
                                        onClick={handleOptionClick}
                                        data-dd-action-name={ACTION.languageSelect}
                                        id={option.value}
                                        aria-label={`Select ${option.label}`}
                                    >
                                        {option.label}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
    )
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setInitializationParams }, dispatch);
}

export default connect(null, mapDispatchToProps)(LanguageSelectInput);
