/**
 * @prettier
 */
import DataDogService from './datadogService';
import selectStorage from './selectStorage';
import { areCookiesEnabled } from './helpers';
import apiService from '../api/api';
import logService from './logService';

export function updateSession(headers) {
    if (headers['session-id']) {
        selectStorage(areCookiesEnabled()).setItem(
            'sessionid',
            headers['session-id']
        );
        DataDogService.log('Session Refreshed');
    }
}

export function regenerateSession(callback, params, self) {
    self.setState({
        refreshAttempted: true,
    });
    // Session Expired
    // Only attempt one refresh
    apiService
        .postRefreshSession()
        .then(response => {
            // Try again after session refreshed
            callback.apply(self, params);
        })
        .catch(err => {
            DataDogService.createError(
                'Unable to Refresh Session, prompting user to reload page'
            );
            logService.error(err);
            self.setState({ loading: false });
            self.setState({
                navigation: {
                    action: 'error',
                    props: {
                        retryAction: () => window.location.reload(),
                        error: err,
                    },
                },
            });
        });
}

export function regenerateSessionNewUI(
    callback,
    params,
    setRefreshAttempted,
    setNavigation
) {
    setRefreshAttempted(true);

    // Session Expired
    // Only attempt one refresh
    apiService
        .postRefreshSession()
        .then(response => {
            // Try again after session refreshed
            callback.apply(null, params);
        })
        .catch(err => {
            DataDogService.createError(
                'Unable to Refresh Session, prompting user to reload page'
            );
            logService.error(err);
            setNavigation({
                action: 'error',
                props: {
                    retryAction: () => window.location.reload(),
                    error: err,
                },
            });
        });
}
