/**
 * @prettier
 */

import axios from 'axios';
import axiosRetry from 'axios-retry';
import { environment } from '../../config/environment';
import logService from '../../services/shared/logService';
import DataDogService from './datadogService';
import {
    areCookiesEnabled,
    showReconnecting,
    hideReconnecting,
} from './helpers';
import selectStorage from './selectStorage';
import { updateSession } from './authenticationService';

/*
 // eslint-disable-next-line no-redeclare
 global window
 */

const client = (() => {
    return axios.create({
        baseURL: environment.apiDomain(),
        timeout: 120000, //2 minute timeout
        headers: {},
    });
})();

function showRetryMessage() {
    showReconnecting();
}

axiosRetry(client, {
    retries: 1,
    shouldResetTimeout: true,
    retryCondition(error) {
        if (error.response) {
            switch (error.response.status) {
                //Don't retry for
                case 420:
                    return false; // Invalid result
                case 429: // Too many Attempts
                    return false;
                case 403: // Forbidden
                    return false;
                default:
                    return true;
            }
        } else {
            return true;
        }
    },
    onRetry: () => {
        showRetryMessage();
    },
});

const request2 = function (options, store) {
    const customHeaders = options.headers;
    const uuid = selectStorage(areCookiesEnabled()).getItem('uuid');

    const onSuccess = function (response) {
        // Keep session up to date
        updateSession(response.headers);

        logService.log('Request Successful!', response);
        hideReconnecting();
        return response.data;
    };

    const onError = function (error) {
        DataDogService.error(error);
        return Promise.reject(error);
    };

    if (process.env.NODE_ENV === 'development') {
        const AUTH_TOKEN = btoa(
            `${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`
        );
        options.headers = {
            Authorization: `Basic ${AUTH_TOKEN}`,
            uuid: `${uuid}`,
            // "uuid": `${window.appData.uuid}`
        };
    } else {
        options.headers = {
            Authorization: `Basic ${process.env.REACT_APP_AUTH_TOKEN}`,
            uuid: `${uuid}`,
            // "uuid": `${window.appData.uuid}`
        };
    }

    const seshId = selectStorage(areCookiesEnabled()).getItem('sessionid');

    if (typeof seshId == 'string') {
        options.headers.sessionid = seshId;
    }

    options.headers = { ...options.headers, ...customHeaders };

    return client(options).then(onSuccess).catch(onError);
};

export default request2;
